import dynamic from "next/dynamic";
import { ComponentType, CSSProperties } from "react";

import { DataAttributes } from "@/components/Utils/DataAttributes";

export interface IconProps {
  size?: number;
  className?: string;
  style?: CSSProperties;
  secondColor?: string;
  onClick?: (e?: MouseEvent|any) => void;
  height?: number;
  width?: number;
  dataAttributes?: DataAttributes
}

export const defaultIconClassNames = "fill-current inline-block";

export const getIcon = (name: string): ComponentType<IconProps> => {
  return dynamic(() =>
    import(`./${name.charAt(0).toUpperCase() + name.slice(1)}Icon`)
  );
};
