import classNames from "classnames";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { routes } from "@/client/routes";
import CloseIcon from "@/components/Icons/CloseIcon";
import HamburgerIcon from "@/components/Icons/HamburgerIcon";
import Logo from "@/components/Logo";
import SidebarFooter from "@/components/Sidebar/SidebarFooter";
import { Company } from "@/components/Types";
import A from "@/components/Typography/A";
import { User } from "@/hooks/useAuth";
import { gtag } from "@/utils/ga";

import SidebarLink from "./SidebarLink";

interface SidebarProps {
  className?: string;
  user?: User;
  company?: Company;
}

const logSidebarClick = (v: string) =>
  gtag("event", "click_sidebar_tab", {
    product: "main",
    view: "sidebar",
    action: "click",
    value: v,
  });

export default function Sidebar({ className, user, company }: SidebarProps) {
  const [open, setOpen] = useState(false);
  const [footerOpen, setFooterOpen] = useState(false);
  const router = useRouter();
  const showSummary = router.query.country === "hr";

  return (
    <div className="dark">
      <div
        className={classNames(
          "fixed z-20 top-0 bottom-0 flex lg:w-64 text-white bg-blueGray50 dark:bg-blueGray900",
          {
            "w-28": open,
            "w-10": !open,
          },
          className
        )}
      >
        <div
          data-cy="sidebar-menu-icon"
          onClick={() => setOpen(!open)}
          className={
            "select-none cursor-pointer absolute lg:hidden pt-4 pl-2 pr-2 " +
            (open ? "right-0" : "")
          }
        >
          {!open && <HamburgerIcon size={24} />}
          {open && <CloseIcon />}
        </div>
        <div
          className={classNames("flex flex-col justify-between pt-8 pb-6 lg:w-full", {
            "w-full": open,
            "w-0 overflow-hidden": !open,
          })}
        >
          <div className="flex flex-col justify-between h-full overflow-x-hidden overflow-y-auto">
            <div>
              <A href={routes.dashboard(router.query)} handleClick={() => logSidebarClick("logo")}>
                <Logo fill={"white"} width={90} className="hidden mb-12 ml-6 dark:block" />
                <Logo fillText={"#08152D"} width={90} className="mb-12 ml-6 dark:hidden" />
              </A>
              <div>
                <SidebarLink
                  title={
                    <FormattedMessage
                      description="Sidebar > Nav link"
                      defaultMessage="Transactions"
                      id="VnfvhS"
                    />
                  }
                  href={routes.transactions(router.query)}
                  icon="exchangeArrows"
                  iconProps={{
                    size: 16,
                  }}
                  status={
                    router.asPath.startsWith(routes.transactions(router.query))
                      ? "Active"
                      : undefined
                  }
                />
                {showSummary && (
                  <SidebarLink
                    title={
                      <FormattedMessage
                        description="Sidebar > Nav link"
                        defaultMessage="Summary"
                        id="WXpOMg"
                      />
                    }
                    href={routes.summary(router.query)}
                    icon="viewList"
                    iconProps={{
                      size: 16,
                    }}
                    status={
                      router.asPath.startsWith(routes.summary(router.query)) ? "Active" : undefined
                    }
                  />
                )}
                <SidebarLink
                  title={
                    <FormattedMessage
                      description="Sidebar > Nav link"
                      defaultMessage="Exports"
                      id="Tb48LW"
                    />
                  }
                  href={routes.exports(router.query)}
                  icon="description"
                  iconProps={{
                    size: 16,
                  }}
                  status={
                    router.asPath.startsWith(routes.exports(router.query)) ? "Active" : undefined
                  }
                />
                <SidebarLink
                  title={
                    <FormattedMessage
                      description="Sidebar > Nav link"
                      defaultMessage="Account"
                      id="EgPL4Y"
                    />
                  }
                  href={routes.account(router.query)}
                  icon="accountCircle"
                  iconProps={{
                    size: 18,
                  }}
                  status={
                    router.asPath.startsWith(routes.account(router.query)) ? "Active" : undefined
                  }
                />
                <SidebarLink
                  title={
                    <FormattedMessage
                      description="Sidebar > Nav link"
                      defaultMessage="Help Centre"
                      id="egaxme"
                    />
                  }
                  href={routes.help(router.query)}
                  external
                  icon="help"
                  className="mt-4"
                />
              </div>
            </div>
          </div>

          <div>
            <SidebarFooter
              name={user?.name}
              company={company?.legal_name}
              open={footerOpen}
              onToggle={() => {
                setFooterOpen(!footerOpen);

                gtag("event", "click_sidebar_footer", {
                  product: "main",
                  view: "sidebar",
                  action: "click",
                  value: footerOpen ? "arrow_collapse" : "arrow_expand",
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
