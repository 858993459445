import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";

import { ThemeColor } from "@/components/Types";
import { convertDataAttributes, DataAttributes } from "@/components/Utils/DataAttributes";
import { getSpacingClass, SpacingProps } from "@/components/Utils/Spacing";

interface PProps extends SpacingProps {
  children: ReactNode;
  color?: ThemeColor;
  className?: string;
  weight?: string;
  type?: "tiny" | "small" | "light" | "medium" | "bold";
  leading?: number;
  fontSize?: string;
  style?: CSSProperties;
  onClick?: () => void;
  title?: string;
  dataAttributes?: DataAttributes;
}

export default function P({
  children,
  className,
  leading,
  fontSize,
  weight,
  style,
  onClick,
  color,
  dataAttributes = {},
  type = "light",
  title,
  spacing,
}: PProps) {
  const spacingClass = getSpacingClass(spacing);
  const classes = {
    tiny: `font-${weight || "normal"} p-0 m-0 text-${fontSize || "2xs"} ${
      leading ? "leading-" + leading : ""
    }`,
    small: `font-${weight || "normal"} p-0 m-0 text-${fontSize || "xs"} leading-${leading || 4}`,
    light: `font-${weight || "light"} p-0 m-0 text-${fontSize || "sm"} leading-${leading || 5}`,
    medium: `font-${weight || "normal"} p-0 m-0 text-${fontSize || "sm"} leading-${leading || 6}`,
    bold: `font-${weight || "bold"} p-0 m-0 text-${fontSize || "sm"} leading-${leading || 6}`,
  };

  const dataAttr = convertDataAttributes(dataAttributes);

  return (
    <p
      {...dataAttr}
      onClick={onClick}
      style={style}
      className={classNames(classes[type], spacingClass, className, {
        [`text-${color}`]: color,
      })}
      title={title}
    >
      {children}
    </p>
  );
}
