import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@/components/Form/Button";
import DepreciatedInput from "@/components/Form/DepreciatedInput";

export default function HeaderBar() {
  const [search, setSearch] = useState("");

  return (
    <div className="flex pt-3 pb-3 pl-6 pr-6 border-bottom-gray100">
      <div className="flex-grow hidden">
        <DepreciatedInput
          value={search}
          onChange={(v) => setSearch(v)}
          leftIcon="search"
          placeholder="Search"
          block={true}
          width="large"
        />
      </div>
      <div className="hidden text-blueGray600">
        <Button leftIcon="lamp" variant="OUTLINED">
          <FormattedMessage
            defaultMessage="Feedback"
            id="q+KA+9"
            description="Header Bar > Feedback"
          />
        </Button>
      </div>
    </div>
  );
}
