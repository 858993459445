interface LoaderProps {
  color?: string;
  size?: number;
  className?: string;
}

export default function Loader({ color, className, size = 32 }: LoaderProps) {
  return (
    <div
      className={`inline-block align-text-bottom rounded-full animate-spin ${color} ${className}`}
      style={{
        width: size + "px",
        height: size + "px",
        borderWidth: `${Math.floor(size / 6)}px`,
        borderLeftColor: `transparent`,
      }}
    ></div>
  );
}
