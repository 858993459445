import Image from "next/image";

import { Images } from "@/components/Utils/Images";

export default function ErrorImage() {
  return (
    <Image
      src={Images.errorPage.src}
      alt={Images.errorPage.alt}
      width={Images.errorPage.width}
      height={Images.errorPage.height}
    />
  );
}
