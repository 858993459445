import classNames from "classnames";
import Head from "next/head";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import config from "@/client/config";
import { BREAKPOINTS } from "@/client/utils";
import HeaderBar from "@/components/HeaderBar/HeaderBar";
import MobileNavigation from "@/components/Sidebar/MobileNavigation/MobileNavigation";
import Sidebar from "@/components/Sidebar/Sidebar";
import { Country } from "@/components/Types";
import A from "@/components/Typography/A";
import getIcon from "@/hooks/getIcon";
import { useAuth } from "@/hooks/useAuth";
import useMediaQuery from "@/hooks/useMediaQuery";

interface SidebarLayoutProps {
  windowTitle?: ReactNode;
  children: ReactNode;
  withPadding?: boolean;
  withHeaderBar?: boolean;
  productGreeting?: ReactNode;
  mockNotification?: boolean;
}

const NotificationPanel = ({ country }: { country: Country }) => {
  const WarningIcon = getIcon("triangleWarningFilled");

  return (
    <div className="flex p-2 border rounded border-red500 bg-red10 lg:p-4">
      {WarningIcon && <WarningIcon className="text-red500 mt-0.5" />}
      <div className="flex-1 pl-2 text-xs lg:text-sm">
        {country === "hr" && (
          <>
            <p className="mb-1 text-blueGray900">
              <FormattedMessage
                description="Dashboard layout > Notification panel > Message"
                defaultMessage="Please update your fiscal certificate as the current one has expired."
                id="udXvir"
              />
            </p>
            <A
              href="https://fonoasupport.zendesk.com/hc/en-us/articles/6006059627793-How-to-update-fiscal-certficate"
              external
              hideExternalIcon
            >
              <FormattedMessage
                description="Dashboard layout > Notification panel > Link to fonoa zendesk"
                defaultMessage="Please click here for instructions"
                id="hLlv9A"
              />
            </A>
          </>
        )}
        {country === "pt" && (
          <>
            <p className="mb-1 text-blueGray900">
              <FormattedMessage
                description="Dashboard layout > Notification panel > Message"
                defaultMessage="TA Credentials you provided do not match credentials you chose in “Gestão de utilizadores”."
                id="fEuwlk"
              />
            </p>
            <A
              href="https://fonoasupport.zendesk.com/hc/en-us/articles/6012442137489-How-to-update-TA-credentials"
              external
              hideExternalIcon
            >
              <FormattedMessage
                description="Dashboard layout > Notification panel > Link to fonoa zendesk"
                defaultMessage="Please click here for instructions"
                id="hLlv9A"
              />
            </A>
          </>
        )}
      </div>
    </div>
  );
};

export default function SidebarLayout({
  windowTitle = "Fonoa Dashboard",
  children,
  withPadding = true,
  withHeaderBar = false,
  mockNotification = Boolean(config.MOCK_NOTIFICATION_BANNER()),
}: SidebarLayoutProps) {
  const auth = useAuth();
  const isDesktop = useMediaQuery(BREAKPOINTS.DESKTOP);

  return (
    <>
      <Head>
        <title>{windowTitle}</title>
      </Head>
      <div className="flex flex-col h-full">
        {isDesktop ? (
          <>
            <div className="relative dark">
              <Sidebar user={auth?.user || undefined} company={auth?.company || undefined} />
            </div>
            <div className="relative flex flex-col w-full h-full pl-10 bg-white lg:pl-64">
              {withHeaderBar && <HeaderBar />}
              {(mockNotification || !auth.company?.tax_credentials?.verified) && (
                <div className="px-6 pt-6">
                  <NotificationPanel country={auth.user?.country as Country} />
                </div>
              )}
              <div
                className={classNames("flex flex-col flex-1 w-full relative min-h-0", {
                  "px-6 py-6": withPadding,
                })}
              >
                {children}
              </div>
            </div>
          </>
        ) : (
          <>
            <MobileNavigation title={windowTitle} />
            {(mockNotification || !auth.company?.tax_credentials?.verified) && (
              <div className="px-4 pt-2">
                <NotificationPanel country={auth.user?.country as Country} />
              </div>
            )}
            <div
              className={classNames(
                "flex flex-col flex-1 w-full relative min-h-0 max-h-screen overflow-auto",
                {
                  "px-4 pb-9 bg-blueGray5": withPadding,
                }
              )}
            >
              {children}
            </div>
          </>
        )}
      </div>
    </>
  );
}
