import { ParsedUrlQuery } from "querystring";

const auth = {
  login: (query: ParsedUrlQuery) => `/${query.country}/login`,
  register: (query: ParsedUrlQuery) => `/${query.country}/register`,
  forgotPassword: (query: ParsedUrlQuery) => `/${query.country}/forgot-password`,
  forgotPasswordSuccess: (query: ParsedUrlQuery) => `/${query.country}/forgot-password/success`,
};

const dashboard = {
  dashboard: (query: ParsedUrlQuery) => `/${query.country}`,
  transactions: (query: ParsedUrlQuery) => `/${query.country}/transactions`,
  saft: (query: ParsedUrlQuery) => `/${query.country}/saf-t`,
  summary: (query: ParsedUrlQuery) => `/${query.country}/summary`,
  account: (query: ParsedUrlQuery) => `/${query.country}/account`,
  exports: (query: ParsedUrlQuery) => `/${query.country}/exports`,
};

export const routes = {
  supportEmail: "support@fonoa.com",
  help: (query: ParsedUrlQuery) => {
    switch (query.country) {
      case "hr":
        return "https://fonoasupport.zendesk.com/hc/en-us/sections/5135805554705-Croatia";
      case "pt":
        return "https://fonoasupport.zendesk.com/hc/en-us/sections/5135859326481-Portugal";
      default:
        return "https://fonoasupport.zendesk.com/hc/en-us";
    }
  },
  ...auth,
  ...dashboard,
};

export type IRouteType = keyof typeof routes;
