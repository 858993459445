import classNames from "classnames";
import { ButtonHTMLAttributes, ComponentType, MouseEvent, ReactNode } from "react";

import { IconProps } from "@/components/Icons/Icon";
import Loader from "@/components/Loader";
import { convertDataAttributes, DataAttributes } from "@/components/Utils/DataAttributes";
import { getSpacingClass, SpacingProps } from "@/components/Utils/Spacing";
import getIcon, { IconType } from "@/hooks/getIcon";

export type ButtonVariant =
  | "FILLED"
  | "FILLED_SUCCESS"
  | "FILLED_WARNING"
  | "FILLED_ALERT"
  | "OUTLINED"
  | "OUTLINED_PRIMARY"
  | "OUTLINED_SUCCESS"
  | "OUTLINED_WARNING"
  | "OUTLINED_ALERT"
  | "TEXT"
  | "TEXT_SUCCESS"
  | "TEXT_WARNING"
  | "TEXT_ALERT"
  | "LINK"
  | "DARK_GRAY";

type ButtonLoaderVariant = "FILLED" | "OUTLINED" | "TEXT" | "LINK";

type SizeVariant = "SMALL" | "REGULAR" | "LARGE";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, SpacingProps {
  variant?: ButtonVariant;
  size?: SizeVariant;
  children?: ReactNode;
  fluid?: boolean;
  leftIcon?: IconType;
  rightIcon?: IconType;
  iconSize?: number;
  disabled?: boolean;
  loading?: boolean;
  shadow?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  dataAttributes?: DataAttributes;
  overrideDisabledStyle?: boolean; // Used to override the default Button disabled styling
  leftIconProps?: Omit<IconProps, "size">;
  rightIconProps?: Omit<IconProps, "size">;
  noPadding?: boolean;
}

const VARIANT_MAPS: Record<ButtonVariant, string> = {
  FILLED:
    "text-white font-medium bg-info border-2 border-info hover:bg-infoHover hover:border-infoHover active:bg-infoActive active:border-infoHover focus:bg-info focus:border-2 focus:border-grayFocusBorder",
  FILLED_SUCCESS:
    "text-white font-medium bg-green border-2 border-green hover:bg-greenHover hover:border-greenHover active:bg-greenActive active:border-greenActive focus:bg-green focus:border-2 focus:border-grayFocusBorder",
  FILLED_WARNING:
    "text-white font-medium bg-warning border-2 border-warning hover:bg-warningHover hover:border-warningHover active:border-warningActive active:bg-warningActive focus:bg-warning focus:border-2 focus:border-grayFocusBorder",
  FILLED_ALERT:
    "text-white font-medium bg-danger border-2 border-danger hover:bg-dangerHover hover:border-dangerHover active:border-dangerActive active:bg-dangerActive focus:bg-danger focus:border-2 focus:border-grayFocusBorder",
  OUTLINED:
    "text-whityText font-medium border border-blueGray200 hover:border-darkBlue focus:border-2 focus:border-grayFocusBorder active:bg-gray24 active:border-darkBlue disabled:text-blueGray200 disabled:border-blueGray200 bg-white disabled:text-blueGray200",
  OUTLINED_PRIMARY:
    "text-primaryBlue500 font-medium border border-primaryBlue500 hover:border-darkBlue focus:border-2 focus:border-primaryBlue600 active:bg-primaryBlue100 active:border-primaryBlue600 disabled:text-blueGray200 disabled:border-blueGray200 bg-primaryBlue10 disabled:text-blueGray200",
  OUTLINED_SUCCESS:
    "text-green font-medium border border-green hover:text-greenHover hover:border-greenHover active:bg-gray24 active:text-greenActive active:border-greenActive focus:border-2 focus:border-grayFocusBorder",
  OUTLINED_WARNING:
    "text-warning font-medium border border-warning hover:text-warningHover hover:border-warningHover active:bg-gray24 active:text-warningActive active:border-warningActive focus:border-2 focus:border-grayFocusBorder",
  OUTLINED_ALERT:
    "text-danger font-medium border border-danger hover:text-dangerHover hover:border-dangerHover active:bg-gray24 active:text-dangerActive active:border-dangerActive focus:border-2 focus:border-grayFocusBorder",
  TEXT: "h-auto text-blueGray600 font-normal hover:underline active:text-darkBlue focus:text-darkBlue disabled:text-blueGray200",
  TEXT_SUCCESS: "h-auto text-success font-normal hover:underline active:text-successActive p-0",
  TEXT_WARNING: "h-auto text-warning font-normal hover:underline active:text-warningActive p-0",
  TEXT_ALERT: "h-auto text-danger font-normal hover:underline active:text-dangerActive p-0",
  LINK: "h-auto text-info font-normal hover:underline active:text-infoActive disabled:blueGray200 p-0",
  DARK_GRAY:
    "text-white font-medium bg-blueGray700 border-2 border-blueGray700 hover:bg-blueGray800 hover:border-blueGray800 active:bg-blueGray800 active:border-blueGray800 focus:border-2 focus:border-grayFocusBorder disabled:bg-blueGray200",
};

const ICON_VARIANT_MAPS: Record<ButtonVariant, string> = {
  FILLED: "text-white",
  FILLED_SUCCESS: "text-white",
  FILLED_WARNING: "text-white",
  FILLED_ALERT: "text-white",
  OUTLINED: "text-darkBlue group-disabled:text-blueGray200",
  OUTLINED_PRIMARY: "text-primaryBlue500 group-disabled:text-blueGray200",
  OUTLINED_SUCCESS: "text-green group-active:text-greenActive",
  OUTLINED_WARNING: "text-warning group-active:text-warningActive",
  OUTLINED_ALERT: "text-danger group-active:text-dangerActive",
  TEXT: "text-blueGray600 group-active:text-darkBlue group-disabled:text-blueGray200",
  TEXT_SUCCESS: "text-success group-hover:text-successHover group-active:text-successActive",
  TEXT_WARNING: "text-warning group-hover:text-warningHover group-active:text-warningActive",
  TEXT_ALERT: "text-danger group-hover:text-dangerHover group-active:text-dangerActive",
  LINK: "text-info group-hover:text-infoHover group-active:text-infoActive group-disabled:text-blueGray200 group-focus:text-infoActive",
  DARK_GRAY: "text-white",
};

const LOADER_VARIANT_MAPS: Record<ButtonLoaderVariant, string> = {
  FILLED: "text-textWhite",
  OUTLINED: "text-whityText",
  TEXT: "text-blueGray600",
  LINK: "text-info",
};

const SIZE_VARIANT_MAPS: Record<SizeVariant, string> = {
  SMALL: "leading-4 h-8 text-xs",
  REGULAR: "leading-7 h-10 text-sm",
  LARGE: "leading-8 h-12 text-sm",
};

export default function Button({
  variant = "FILLED",
  size = "REGULAR",
  spacing,
  children,
  leftIcon,
  rightIcon,
  fluid,
  iconSize,
  shadow = false,
  disabled = false,
  loading = false,
  dataAttributes = {},
  onClick,
  className,
  overrideDisabledStyle,
  leftIconProps = {},
  rightIconProps = {},
  noPadding,
  ...props
}: ButtonProps) {
  const spacingClass = getSpacingClass(spacing);
  const LeftIconComponent: ComponentType<IconProps> | null = getIcon(leftIcon);
  const RightIconComponent: ComponentType<IconProps> | null = getIcon(rightIcon);

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    if (!disabled && onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  const dataAttr = convertDataAttributes(dataAttributes);

  const isFilledVariant = ["FILLED", "FILLED_ALERT", "FILLED_WARNING", "FILLED_SUCCESS"].includes(
    variant
  );

  return (
    <button
      {...dataAttr}
      disabled={disabled}
      onClick={!loading ? handleSubmit : undefined}
      type="button"
      className={classNames(
        variant === "FILLED" && getLoadingState(loading), // only covers FILLED variant at the moment with a light blue background + white loader
        VARIANT_MAPS[variant],
        SIZE_VARIANT_MAPS[size],
        spacingClass,
        "group rounded focus:outline-none whitespace-nowrap disabled:cursor-not-allowed flex items-center justify-center",
        {
          "w-full": fluid,
          shadow: shadow,
          "disabled:bg-blueGray200 disabled:border-blueGray200":
            isFilledVariant && !overrideDisabledStyle,
          "disabled:bg-opacity-50 disabled:border-transparent": overrideDisabledStyle,
          "py-1 px-4": !isTextVariant(variant) && !noPadding,
        },
        className
      )}
      {...props}
    >
      {LeftIconComponent && !loading && (
        <LeftIconComponent
          {...leftIconProps}
          className={classNames(
            "align-middle",
            ICON_VARIANT_MAPS[variant],
            leftIconProps.className,
            {
              "mr-2": Boolean(children),
            }
          )}
          size={iconSize || (size === "SMALL" ? 14 : 16)}
        />
      )}
      {LeftIconComponent && loading && (
        <Loader
          className={classNames("align-middle", LOADER_VARIANT_MAPS[variant], {
            "mr-2": Boolean(children),
          })}
          size={16}
        />
      )}
      {loading && !LeftIconComponent && !RightIconComponent && (
        <Loader
          className={classNames("align-middle mx-3.5", LOADER_VARIANT_MAPS[variant])}
          size={16}
        />
      )}
      {!loading && <span className="block mt-px truncate">{children}</span>}
      {loading && (Boolean(LeftIconComponent) || Boolean(RightIconComponent)) ? children : null}
      {RightIconComponent && !loading && (
        <RightIconComponent
          {...rightIconProps}
          className={classNames(
            "align-middle",
            ICON_VARIANT_MAPS[variant],
            rightIconProps.className,
            {
              "ml-2": Boolean(children),
            }
          )}
          size={iconSize || (size === "SMALL" ? 14 : 16)}
        />
      )}
      {RightIconComponent && loading && (
        <Loader
          className={classNames("align-middle ml-2", LOADER_VARIANT_MAPS[variant])}
          size={16}
        />
      )}
    </button>
  );
}

function getLoadingState(loading: boolean): string {
  return loading
    ? "bg-primaryBlue200 border-primaryBlue200 hover:bg-primaryBlue200 hover:border-primaryBlue200 active:bg-primaryBlue200 active:border-primaryBlue200 focus:bg-primaryBlue200 focus:border-primaryBlue200 cursor-not-allowed"
    : "";
}

function isTextVariant(variant: ButtonVariant): boolean {
  return ["TEXT", "TEXT_SUCCESS", "TEXT_ALERT", "TEXT_WARNING", "LINK"].includes(variant);
}
