import classNames from "classnames";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ArrowUpIcon({
  size = 32,
  className = "",
  style,
  onClick
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 12 7`}
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      style={style}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.87352L10.8493 7L6 2.25296L1.15074 7L8.55194e-07 5.87352L5.30047 0.684789C5.68919 0.304254 6.31081 0.304254 6.69953 0.684789L12 5.87352Z"
      />
    </svg>
  );
}
