// This is a copy of sizes from tailwind.config.js
const sizes = [
  0,
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  12.5,
  14,
  15,
  16,
  18,
  20,
  22,
  23,
  24,
  28,
  32,
  36,
  40,
  44,
  48,
  52,
  56,
  60,
  63,
  72,
  80,
  96,
  "px",
] as const;

type MarginSide =
  | "mx"
  | "my"
  | "mt"
  | "mr"
  | "mb"
  | "ml"
  | "-mx"
  | "-my"
  | "-mt"
  | "-mr"
  | "-mb"
  | "-ml";
type Sizes = typeof sizes[number];

export type Spacing = Partial<Record<MarginSide, Sizes>>;

export interface SpacingProps {
  spacing?: Spacing;
}

export const getSpacingClass = (spacing: SpacingProps["spacing"] = {}): string => {
  return Object.entries(spacing)
    .map(([key, value]) => `${key}-${value}`)
    .join(" ");
};
