import classNames from "classnames";

import { convertDataAttributes } from "@/components/Utils/DataAttributes";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function HamburgerIcon({
  size = 32,
  className = "",
  style,
  onClick,
  dataAttributes = {}

}: IconProps) {
  const dataAttr = convertDataAttributes(dataAttributes);

  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      style={style} viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      {...dataAttr}

    >
<path d="M0.5 12H18.5V10H0.5V12ZM0.5 7H18.5V5H0.5V7ZM0.5 0V2H18.5V0H0.5Z" fill="currentColor"/>
</svg>

  );
}
