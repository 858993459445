import classNames from 'classnames';

import { defaultIconClassNames, IconProps } from "./Icon";


export default function ContactSupportIcon({
  size = 16,
  className = "",
  style
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 .5 16 17`}
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.33333 15.7266V13.3266C3.99333 13.1533 1.33333 10.38 1.33333 6.99996C1.33333 3.50663 4.17333 0.666626 7.66667 0.666626C11.16 0.666626 14 3.50663 14 6.99996C14 10.3 11.7067 13.62 8.28667 15.2666L7.33333 15.7266ZM7.66667 1.99996C4.90667 1.99996 2.66667 4.23996 2.66667 6.99996C2.66667 9.75996 4.90667 12 7.66667 12H8.66667V13.5333C11.0933 12 12.6667 9.47996 12.6667 6.99996C12.6667 4.23996 10.4267 1.99996 7.66667 1.99996ZM7 9.66663H8.33333V11H7V9.66663ZM8.33333 8.66663H7C7 6.49996 9 6.66663 9 5.33329C9 4.59996 8.4 3.99996 7.66667 3.99996C6.93333 3.99996 6.33333 4.59996 6.33333 5.33329H5C5 3.85996 6.19333 2.66663 7.66667 2.66663C9.14 2.66663 10.3333 3.85996 10.3333 5.33329C10.3333 6.99996 8.33333 7.16663 8.33333 8.66663Z" fill="#2D69DC" />
    </svg>
  );
}
