import dynamic from "next/dynamic";
import { ComponentType, memo } from "react";

import { IconProps } from "@/components/Icons/Icon";

export const defaultIconClassNames = "fill-current inline-block";

export type IconType = keyof typeof Icons;

export const Icons = {
  alignCenter: dynamic(() => import("@/components/Icons/AlignCenterIcon")),
  alignLeft: dynamic(() => import("@/components/Icons/AlignLeftIcon")),
  alignRight: dynamic(() => import("@/components/Icons/AlignRightIcon")),
  archive: dynamic(() => import("@/components/Icons/ArchiveIcon")),
  arrowDown: dynamic(() => import("@/components/Icons/ArrowDownIcon")),
  arrowLeft: dynamic(() => import("@/components/Icons/ArrowLeftIcon")),
  arrowRight: dynamic(() => import("@/components/Icons/ArrowRightIcon")),
  arrowForward: dynamic(() => import("@/components/Icons/ArrowForwardIcon")),
  assignment: dynamic(() => import("@/components/Icons/AssignmentIcon")),
  arrowUp: dynamic(() => import("@/components/Icons/ArrowUpIcon")),
  boxes: dynamic(() => import("@/components/Icons/BoxesIcon")),
  business: dynamic(() => import("@/components/Icons/BusinessIcon")),
  calendar: dynamic(() => import("@/components/Icons/CalendarIcon")),
  checkBox: dynamic(() => import("@/components/Icons/CheckBoxIcon")),
  check: dynamic(() => import("@/components/Icons/CheckIcon")),
  circleCheck: dynamic(() => import("@/components/Icons/CircleCheckIcon")),
  circleClose: dynamic(() => import("@/components/Icons/CircleCloseIcon")),
  circleCross: dynamic(() => import("@/components/Icons/CircleCrossIcon")),
  close: dynamic(() => import("@/components/Icons/CloseIcon")),
  coding: dynamic(() => import("@/components/Icons/CodingIcon")),
  copy: dynamic(() => import("@/components/Icons/CopyIcon")),
  demo: dynamic(() => import("@/components/Icons/DemoIcon")),
  developer: dynamic(() => import("@/components/Icons/DeveloperIcon")),
  dots: dynamic(() => import("@/components/Icons/DotsIcon")),
  download: dynamic(() => import("@/components/Icons/DownloadIcon")),
  edit: dynamic(() => import("@/components/Icons/EditIcon")),
  errorFill: dynamic(() => import("@/components/Icons/ErrorFillIcon")),
  error: dynamic(() => import("@/components/Icons/ErrorIcon")),
  emptyFolder: dynamic(() => import("@/components/Icons/EmptyFolderIcon")),
  emptyInvoice: dynamic(() => import("@/components/Icons/EmptyInvoiceIcon")),
  envelope: dynamic(() => import("@/components/Icons/EnvelopeIcon")),
  file: dynamic(() => import("@/components/Icons/FileIcon")),
  fileCheck: dynamic(() => import("@/components/Icons/FileCheckIcon")),
  fileColor: dynamic(() => import("@/components/Icons/FileColorIcon")),
  filter: dynamic(() => import("@/components/Icons/FilterIcon")),
  filterAlt: dynamic(() => import("@/components/Icons/FilterAltIcon")),
  globe: dynamic(() => import("@/components/Icons/GlobeIcon")),
  hamburger: dynamic(() => import("@/components/Icons/HamburgerIcon")),
  help: dynamic(() => import("@/components/Icons/HelpIcon")),
  helpOutline: dynamic(() => import("@/components/Icons/HelpOutlineIcon")),
  home: dynamic(() => import("@/components/Icons/HomeIcon")),
  info: dynamic(() => import("@/components/Icons/InfoIcon")),
  insights: dynamic(() => import("@/components/Icons/InsightsIcon")),
  invoice: dynamic(() => import("@/components/Icons/InvoiceIcon")),
  invoiceError: dynamic(() => import("@/components/Icons/InvoiceErrorIcon")),
  key: dynamic(() => import("@/components/Icons/KeyIcon")),
  lamp: dynamic(() => import("@/components/Icons/LampIcon")),
  lifebuoy: dynamic(() => import("@/components/Icons/LifebuoyIcon")),
  lock: dynamic(() => import("@/components/Icons/LockIcon")),
  longArrowUp: dynamic(() => import("@/components/Icons/LongArrowUpIcon")),
  magic: dynamic(() => import("@/components/Icons/MagicIcon")),
  mail: dynamic(() => import("@/components/Icons/MailIcon")),
  minus: dynamic(() => import("@/components/Icons/MinusIcon")),
  outside: dynamic(() => import("@/components/Icons/OutsideIcon")),
  picture: dynamic(() => import("@/components/Icons/PictureIcon")),
  plus: dynamic(() => import("@/components/Icons/PlusIcon")),
  plusPill: dynamic(() => import("@/components/Icons/PlusPillIcon")),
  print: dynamic(() => import("@/components/Icons/PrintIcon")),
  profile: dynamic(() => import("@/components/Icons/ProfileIcon")),
  refresh: dynamic(() => import("@/components/Icons/RefreshIcon")),
  renew: dynamic(() => import("@/components/Icons/RenewIcon")),
  search: dynamic(() => import("@/components/Icons/SearchIcon")),
  settings: dynamic(() => import("@/components/Icons/SettingsIcon")),
  sort: dynamic(() => import("@/components/Icons/SortIcon")),
  statusError: dynamic(() => import("@/components/Icons/StatusErrorIcon")),
  statusInfo: dynamic(() => import("@/components/Icons/StatusInfoIcon")),
  statusSuccess: dynamic(() => import("@/components/Icons/StatusSuccessIcon")),
  statusWarning: dynamic(() => import("@/components/Icons/StatusWarningIcon")),
  store: dynamic(() => import("@/components/Icons/StoreIcon")),
  support: dynamic(() => import("@/components/Icons/SupportIcon")),
  tooltip: dynamic(() => import("@/components/Icons/TooltipIcon")),
  trash: dynamic(() => import("@/components/Icons/TrashIcon")),
  unlock: dynamic(() => import("@/components/Icons/UnlockIcon")),
  view: dynamic(() => import("@/components/Icons/ViewIcon")),
  warning: dynamic(() => import("@/components/Icons/WarningIcon")),
  triangleWarning: dynamic(() => import("@/components/Icons/TriangleWarningIcon")),
  triangleWarningFilled: dynamic(() => import("@/components/Icons/TriangleWarningFilledIcon")),
  autoRenew: dynamic(() => import("@/components/Icons/AutoRenew")),
  autoAwesomeMotion: dynamic(() => import("@/components/Icons/AutoAwesomeMotion")),
  fileCopy: dynamic(() => import("@/components/Icons/FileCopy")),
  fileCode: dynamic(() => import("@/components/Icons/FileCode")),
  eye: dynamic(() => import("@/components/Icons/EyeIcon")),
  closedEye: dynamic(() => import("@/components/Icons/ClosedEyeIcon")),
  phone: dynamic(() => import("@/components/Icons/PhoneIcon")),
  csvFile: dynamic(() => import("@/components/Icons/CsvFile")),
  chevronUp: dynamic(() => import("@/components/Icons/ChevronUpIcon")),
  chevronDown: dynamic(() => import("@/components/Icons/ChevronDownIcon")),
  inviteUser: dynamic(() => import("@/components/Icons/InviteUserIcon")),
  transitionArrows: dynamic(() => import("@/components/Icons/TransitionArrowsIcon")),
  hourglass: dynamic(() => import("@/components/Icons/HourglassIcon")),
  unarchive: dynamic(() => import("@/components/Icons/UnarchiveIcon")),
  upload: dynamic(() => import("@/components/Icons/UploadIcon")),
  uploadFile: dynamic(() => import("@/components/Icons/UploadFileIcon")),
  triangleDown: dynamic(() => import("@/components/Icons/TriangleDownIcon")),
  clock: dynamic(() => import("@/components/Icons/ClockIcon")),
  globeOutline: dynamic(() => import("@/components/Icons/GlobeOutlineIcon")),
  callCenter: dynamic(() => import("@/components/Icons/CallCenterIcon")),
  multipleTaxation: dynamic(() => import("@/components/Icons/MultipleTaxation")),
  sentEmail: dynamic(() => import("@/components/Icons/SentEmailIcon")),
  timerOff: dynamic(() => import("@/components/Icons/TimerOffIcon")),
  hourglassBottom: dynamic(() => import("@/components/Icons/HourglassBottomIcon")),
  spinner: dynamic(() => import("@/components/Icons/SpinnerIcon")),
  switchError: dynamic(() => import("@/components/Icons/SwitchErrorIcon")),
  exchangeArrows: dynamic(() => import("@/components/Icons/ExchangeArrowsIcon")),
  description: dynamic(() => import("@/components/Icons/DescriptionIcon")),
  viewList: dynamic(() => import("@/components/Icons/ViewListIcon")),
  accountCircle: dynamic(() => import("@/components/Icons/AccountCircleIcon")),
  bolt: dynamic(() => import("@/components/Icons/BoltIcon")),
  code: dynamic(() => import("@/components/Icons/CodeIcon")),
  logout: dynamic(() => import("@/components/Icons/LogoutIcon")),
  questionAnswer: dynamic(() => import("@/components/Icons/QuestionAnswerIcon")),
  doneAll: dynamic(() => import("@/components/Icons/DoneAllIcon")),
  flag: dynamic(() => import("@/components/Icons/FlagIcon")),
};

export function Icon(props: IconProps & { name: IconType }) {
  const { name, ...otherProps } = props;
  const Icon: ComponentType<IconProps> | null = getIcon(name);
  return Icon ? <Icon {...otherProps} /> : null;
}

export default function getIcon(name: IconType | undefined): ComponentType<IconProps> | null {
  if (!name) return null;
  return memo(Icons[name]);
}
