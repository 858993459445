import axios from "axios";

import { getUserData, isAuthenticated, signout } from "./auth";
import { routes } from "./routes";
import { isBrowser, localStorageKeys } from "./utils";

const setupAxiosInterceptors = (): void => {
  axios.interceptors.request.use(function (config) {
    const accessToken = process.browser ? localStorage.getItem(localStorageKeys.id_token) : null;
    if (accessToken && isAuthenticated()) {
      config.headers.common["x-access-token"] = `${accessToken}`;
    }
    config.headers.common["X-Requested-With"] = "XMLHttpRequest";
    config.headers.common["Accept"] = "application/json";
    return config;
  });

  axios.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401 || error.response?.data?.message === "401 Unauthorized") {
      // if a users session has expired, trigger the sign out flow
      if (isBrowser) {
        const userProfile = getUserData();
        const countryFromUser = userProfile?.country;
        const countryFromUrl = location.pathname.split("/")?.[1];

        signout();
        location.href = routes.login({
          country: countryFromUser || countryFromUrl,
        });
      }
    }
    return Promise.reject(error);
  });
};

export default setupAxiosInterceptors;
