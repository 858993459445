import classNames from "classnames";
import {
  ChangeEvent,
  ComponentType,
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { FormError } from "@/components/Form/Form";
import { IconProps } from "@/components/Icons/Icon";
import { CustomError, ThemeColorBg, ThemeColorText } from "@/components/Types";
import { H6 } from "@/components/Typography/H";
import { convertDataAttributes, DataAttributes } from "@/components/Utils/DataAttributes";
import getIcon, { IconType } from "@/hooks/getIcon";

type SizeVariant = "EXTRA_SMALL" | "SMALL" | "REGULAR";

export type IconVariant = "SUCCESS" | "ALERT" | "GRAY";

const SIZE_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "h-6",
  SMALL: "",
  REGULAR: "h-10",
};

const LEFT_ICON_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "",
  SMALL: "left-6",
  REGULAR: "left-6",
};

const RIGHT_ICON_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "",
  SMALL: "right-6",
  REGULAR: "right-6",
};

const ICON_VARIANT_MAPS: Record<IconVariant, string> = {
  SUCCESS: "text-success",
  ALERT: "text-danger",
  GRAY: "text-blueGray600",
};

interface InputStatus {
  textClass: string;
  colorClass: string;
  borderClass: string;
  ringClass: string;
  wClass: string;
  LeftIconComponent: ComponentType<IconProps> | null;
  RightIconComponent: ComponentType<IconProps> | null;
  plClass: string;
  prClass: string;
  errorsFiltered: FormError[];
}

export interface DepreciatedInputProps {
  id?: string;
  className?: string;
  iconVariant?: IconVariant;
  leftIcon?: IconType | undefined;
  rightIcon?: IconType | undefined;
  defaultColor?: ThemeColorText;
  bgColor?: ThemeColorBg;
  value: string;
  type?: "text" | "password" | "number" | "email";
  width?: string;
  size?: SizeVariant;
  style?: CSSProperties;
  block?: boolean;
  error?: boolean;
  customError?: CustomError[];
  placeholder?: string;
  helper?: string;
  helperRenderer?: (helper: string) => ReactNode;
  success?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  formErrors?: FormError[];
  onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onKeyDown?: (e) => void;
  dataAttributes?: DataAttributes;
  autoFocus?: boolean;
  onRightIconClick?: () => void;
  renderErrorContainer?: boolean;
  title?: string;
  leftIconSize?: number;
  rightIconSize?: number;
  onFocus?: () => void;
  onBlur?: () => void;
}

export default function DepreciatedInput({
  id,
  value,
  type = "text",
  block = false,
  error = false,
  customError,
  rounded = true,
  success = false,
  size = "SMALL",
  style,
  width = "regular",
  defaultColor,
  bgColor,
  helperRenderer = (h: string) => h,
  dataAttributes = {},
  placeholder,
  helper,
  leftIcon,
  rightIcon,
  iconVariant,
  disabled,
  required,
  onChange,
  onClick,
  minLength,
  maxLength,
  className,
  formErrors,
  autoFocus,
  onRightIconClick,
  renderErrorContainer,
  leftIconSize,
  rightIconSize,
  onFocus,
  onBlur,
  ...props
}: DepreciatedInputProps) {
  const inputElement = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);

  function handleFocus(val: boolean) {
    if (val) {
      setFocused(true);
      if (onFocus) onFocus();
    } else {
      setFocused(false);
      if (onBlur) onBlur();
    }
  }

  const getInputStatus = useCallback<() => InputStatus>(() => {
    const errorsFiltered =
      inputElement.current && formErrors
        ? formErrors.filter(
            (err) => inputElement?.current?.id === err?.id || err?.element === inputElement.current
          )
        : [];

    const errorFixed = error || customError || errorsFiltered.length > 0;
    const rightIconFixed = errorFixed ? "error" : success ? "check" : rightIcon;
    const color = errorFixed ? "bg-dangerBg" : defaultColor;
    const borderColor = errorFixed ? "border-danger" : "border-white";
    const borderFocusColor = errorFixed ? "border-dangerLight" : "border-white";

    function getRightIcon() {
      if (errorsFiltered.length > 0 || customError || error) {
        return getIcon("error");
      }
      if (rightIcon) {
        return getIcon(rightIcon);
      }
      return null;
    }

    return {
      textClass: errorFixed ? "text-danger" : success ? "text-success" : "",
      colorClass: (bgColor ? bgColor : color) || "",
      borderClass: classNames(`border `, {
        [borderColor]: borderColor,
        [`focus:${borderFocusColor}`]: borderFocusColor,
      }),
      ringClass: `focus:outline-none focus:ring-1 ${
        errorFixed
          ? "border-danger focus:border-dangerLight ring-dangerLight"
          : "border-blueGray200 focus:border-infoLight ring-infoLight"
      }`,
      wClass: { small: "w-30", regular: "w-60", large: "w-96", full: "w-full" }[width] || width,
      LeftIconComponent: getIcon(leftIcon),
      RightIconComponent: getRightIcon(),
      plClass: leftIcon ? "pl-10" : "pl-4",
      prClass: rightIconFixed ? "pr-10" : "pr-2",
      errorsFiltered: customError || errorsFiltered,
    };
  }, [error, success, leftIcon, rightIcon, formErrors, inputElement]);

  const [status, setStatus] = useState<InputStatus>(getInputStatus());

  useEffect(() => {
    setStatus(getInputStatus());
  }, [error, success, leftIcon, rightIcon, formErrors, inputElement, getInputStatus]);

  const dataAttr = convertDataAttributes(dataAttributes);
  const hasErrors = status.errorsFiltered && status.errorsFiltered.length > 0;

  return (
    <span
      className={classNames("relative", status.wClass, {
        block,
        "inline-block": !block,
      })}
    >
      <div className="relative">
        {status.LeftIconComponent && (
          <status.LeftIconComponent
            className={classNames(
              "absolute top-1/2 transform -translate-y-1/2 -translate-x-1/2",
              LEFT_ICON_MAPS[size],
              ICON_VARIANT_MAPS[iconVariant || ""],
              status.textClass
            )}
            size={leftIconSize || 16}
          />
        )}
        {status.RightIconComponent && (
          <div
            onClick={onRightIconClick}
            className={classNames({
              "cursor-pointer": onRightIconClick,
            })}
          >
            <status.RightIconComponent
              className={classNames(
                "absolute top-1/2 transform -translate-y-1/2 translate-x-1/2",
                RIGHT_ICON_MAPS[size],
                ICON_VARIANT_MAPS[iconVariant || ""],
                status.textClass
              )}
              size={rightIconSize || 16}
            />
          </div>
        )}
        <input
          id={id}
          ref={inputElement}
          required={required}
          placeholder={placeholder}
          type={type}
          style={style}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          value={value}
          autoFocus={autoFocus}
          onChange={(e) => onChange?.(e.target.value, e)}
          onClick={onClick}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          className={classNames(
            `p-0 text-sm text-darkBlue leading-8 disabled:opacity-50`,
            SIZE_MAPS[size],
            { rounded },
            status.colorClass,
            status.borderClass,
            status.ringClass,
            status.wClass,
            status.plClass,
            status.prClass,
            className
          )}
          {...dataAttr}
          {...props}
        />
      </div>
      <div
        className={classNames("h-5 mt-1", {
          hidden: !renderErrorContainer && !hasErrors,
        })}
      >
        {hasErrors && (
          <H6 className="text-danger" weight="regular">
            {status.errorsFiltered?.[0]?.message ?? ""}
          </H6>
        )}
      </div>
      {helper && focused && (
        <div className="absolute -top-2" style={{ right: "-25rem" }}>
          {helperRenderer(helper)}
        </div>
      )}
    </span>
  );
}
