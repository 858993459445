import DashboardError from "@/components/Pages/Error/components/DashboardError";
import DefaultError from "@/components/Pages/Error/components/DefaultError";

interface ErrorProps {
  errorCode?: number;
  dashboard?: boolean;
}

function Error({ errorCode = 500, dashboard = false }: ErrorProps) {
  return dashboard ? (
    <DashboardError errorCode={errorCode} />
  ) : (
    <DefaultError errorCode={errorCode} />
  );
}

export default Error;
