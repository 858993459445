import axios from "axios";

export const getCompany = async (companyId: string) => {
  const res = await axios.get(`/api/companies/${companyId}`);

  return res?.data;
};

/**
 * It updates a company in the database.
 * @param {string} companyId - The id of the company you want to update.
 * @param {any} data - The data to be sent to the server.
 */
export const patchCompany = async (companyId: string, data: any, country: string) => {
  const res = await axios.patch(`/api/companies/${companyId}`, data, {
    params: {
      country,
    },
  });

  return res?.data;
};
