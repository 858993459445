import * as Sentry from "@sentry/nextjs";
import { Event } from "@sentry/nextjs";
import { EventHint, SamplingContext } from "@sentry/types";

export const environmentAllowList = ["demo", "production"];

export function initSentry(ingestUrl: string, environment: string, samplingRate: number) {
  Sentry.init({
    dsn: ingestUrl,
    // Configures the sample rate for error events, in the range of 0.0 to 1.0.
    // The default is 1.0 which means that 100% of error events are sent.
    // If set to 0.1 only 10% of error events will be sent. Events are picked randomly.
    sampleRate: 1,
    beforeSend: (event: Event, hint: EventHint) => {
      // In dev environment, log to console and don't forward to Sentry
      const exception = hint.originalException as any;
      if (!environmentAllowList.includes(environment)) {
        console.error(
          "LOG SENTRY ERROR",
          exception?.toString() || hint.syntheticException?.message
        );
        return null; // Don't send to Sentry
      }

      return removePII(event);
    },
    tracesSampler: (samplingContext) => {
      return sampleTrace(samplingContext, samplingRate);
    },
    environment: environment,
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function removePII(event: Event): Event {
  // This callback can mostly be used for removing PII of events
  // Currently we do not identify a user in Sentry so treat this as an example
  // Ref: https://docs.sentry.io/platforms/javascript/data-management/sensitive-data/
  if (event.user) {
    delete event.user.email;
  }

  return event;
}

function sampleTrace(samplingContext: SamplingContext, samplingRate: number): number | boolean {
  // Sampler for transactions
  if (samplingContext.parentSampled !== undefined) {
    return samplingContext.parentSampled;
  }

  if (samplingContext.transactionContext.name.includes("/status")) {
    return 0;
  }

  return samplingRate;
}
