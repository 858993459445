import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import Button from "@/components/Form/Button";
import ContactSupportIcon from "@/components/Icons/ContactSupportIcon";
import A from "@/components/Typography/A";

import ErrorImage from "./ErrorImage";
import ErrorTitle from "./ErrorTitle";

function DefaultError({ errorCode }) {
  const router = useRouter();

  return (
    <div className="mt-40 text-center">
      <ErrorImage />
      <ErrorTitle errorCode={errorCode} />
      <Button className="mx-auto mt-8" onClick={() => router.back()}>
        <FormattedMessage
          description="Dashboard Error > Back to dashboard"
          defaultMessage="Take me back"
          id="VbWPZk"
        />
      </Button>
      <A
        className="flex items-center justify-center mt-5 text-sm font-normal text-primaryBlue500"
        href="https://www.fonoa.com/contact-us"
      >
        <ContactSupportIcon className="mr-2" />
        <FormattedMessage
          description="Dashboard Error > Contact us"
          defaultMessage="Contact Us"
          id="KFdgio"
        />
      </A>
    </div>
  );
}

export default DefaultError;
