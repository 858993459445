export type ImageType = keyof typeof Images;

export const Images = {
  productInvoicing: {
    src: "/img/product-invoicing.png",
    alt: "Product Invoicing",
    width: 444,
    height: 260,
  },
  productLookup: {
    src: "/img/product-lookup.png",
    alt: "Product Lookup",
    width: 444,
    height: 260,
  },
  productReporting: {
    src: "/img/product-reporting.png",
    alt: "Product Reporting",
    width: 444,
    height: 260,
  },
  productTax: {
    src: "/img/product-tax.png",
    alt: "Product Tax",
    width: 444,
    height: 260,
  },
  iconInvoicing: {
    src: "/img/icons/Invoicing.svg",
    alt: "Invoicing Icon",
    width: 54,
    height: 54,
  },
  iconReporting: {
    src: "/img/icons/Reporting.svg",
    alt: "Reporting Icon",
    width: 54,
    height: 54,
  },
  iconTaxEngine: {
    src: "/img/icons/TaxEngine.svg",
    alt: "Tax Engine Icon",
    width: 54,
    height: 54,
  },
  iconTaxStatus: {
    src: "/img/icons/TaxStatus.svg",
    alt: "Tax Status Icon",
    width: 54,
    height: 54,
  },
  invoicingTemplates: {
    src: "/img/invoicing/InvoiceTemplates.png",
    alt: "Invoicing Templates",
    width: 744,
    height: 576,
  },
  lookupHero: {
    src: "/img/lookup/LookupHero.png",
    alt: "Lookup product",
    width: 976,
    height: 496,
  },
  lookupUploadFormatLeft: {
    src: "/img/lookup/LookupUploadFormatLeft.png",
    alt: "TIN format",
    width: 263,
    height: 183,
  },
  lookupUploadFormatRight: {
    src: "/img/lookup/LookupUploadFormatRight.png",
    alt: "TIN format for Turkey",
    width: 341,
    height: 181,
  },
  reportingHero: {
    src: "/img/reporting/ReportingHero.png",
    alt: "Reporting product",
    width: 1130,
    height: 642,
  },
  taxHero: {
    src: "/img/tax/TaxHero.png",
    alt: "Tax product",
    width: 929,
    height: 613,
  },
  emptyTableResults: {
    src: "/img/empty-table-results.svg",
    alt: "Empty Table Results",
    width: 118,
    height: 90,
  },
  errorTableResults: {
    src: "/img/error-table-results.svg",
    alt: "Table Results Error",
    width: 84,
    height: 94,
  },
  iconEntreprise: {
    src: "/img/icons/entreprise.svg",
    alt: "Entreprise",
    width: 28,
    height: 28,
  },
  notFoundPage: {
    src: "/img/404.svg",
    alt: "Not Found Page",
    width: 452,
    height: 146,
  },
  errorPage: {
    src: "/img/error.svg",
    alt: "Error Page",
    width: 355,
    height: 123,
  },
};
