import { useAuth } from "@/hooks/useAuth";

import Error from "./Error";

function ErrorProxy({ statusCode = 500 }) {
  const auth = useAuth();
  return <Error errorCode={statusCode} dashboard={auth.isLoggedIn} />;
}

export default ErrorProxy;
