import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { Fragment, ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { routes } from "@/client/routes";
import ArrowRightIcon from "@/components/Icons/ArrowRightIcon";
import HamburgerIcon from "@/components/Icons/HamburgerIcon";
import Logo from "@/components/Logo";
import A from "@/components/Typography/A";
import { convertDataAttributes, DataAttributes } from "@/components/Utils/DataAttributes";
import getIcon, { IconType } from "@/hooks/getIcon";
import { useAuth } from "@/hooks/useAuth";

interface DrawerLinkProps {
  href?: string;
  active?: boolean;
  icon: IconType;
  title: ReactNode;
  className?: string;
  onClick?: () => void;
  external?: boolean;
  dataAttribues?: DataAttributes;
}

const DrawerLink = ({
  href,
  onClick,
  active = false,
  icon,
  title,
  className,
  external,
  dataAttribues,
}: DrawerLinkProps) => {
  const Icon = getIcon(icon);
  const dataAttr = convertDataAttributes(dataAttribues);

  return (
    <div className={classNames("flex items-center justify-center", className)} {...dataAttr}>
      <A
        href={href}
        onClick={onClick}
        className={classNames("group", { "text-fonoaGreen": active })}
        basic
        external={external}
        hideExternalIcon
      >
        <div className="flex flex-col items-center text-center">
          <div>{Icon && <Icon size={24} secondColor="black" />}</div>
          <p className="text-xs mt-1.5 font-medium">{title}</p>
        </div>
      </A>
    </div>
  );
};

interface Props {
  className?: string;
  title?: ReactNode;
}

const MobileNavigation = ({ title }: Props) => {
  const auth = useAuth();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const logout = () => {
    auth.logout();
    router.push(routes.login(router.query));
  };

  return (
    <div className="relative w-full bg-white">
      <div className="px-5 pt-4 pb-3">
        {/* Header with Fonoa icon on the left, page title in the center and hamburger icon on the right */}
        <div className="flex items-center justify-between w-full">
          <Logo width={60} />

          <h2 className="-ml-10 text-xl font-medium text-primaryBlue900">{title}</h2>

          <HamburgerIcon
            size={16}
            onClick={() => setIsOpen(!isOpen)}
            className="cursor-pointer"
            dataAttributes={{ cy: "sidebar-menu-icon" }}
          />
        </div>
      </div>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={classNames(
            "fixed z-50 top-0 bottom-0 flex justify-end w-screen h-screen bg-black bg-opacity-50 left-0 right-0"
          )}
          onClick={() => setIsOpen(false)}
        >
          <div className="h-screen p-4.5 text-white w-28 bg-blueGray900">
            <ArrowRightIcon size={20} onClick={() => setIsOpen(false)} className="cursor-pointer" />

            <div className="mt-12 space-y-12">
              <DrawerLink
                href={routes.transactions(router.query)}
                active={router.asPath.startsWith(routes.transactions(router.query))}
                icon="exchangeArrows"
                title={
                  <FormattedMessage
                    description="Sidebar > Nav link"
                    defaultMessage="Transactions"
                    id="VnfvhS"
                  />
                }
              />
              {router.query.country === "hr" && (
                <DrawerLink
                  href={routes.summary(router.query)}
                  active={router.asPath.startsWith(routes.summary(router.query))}
                  icon="viewList"
                  title={
                    <FormattedMessage
                      description="Sidebar > Nav link"
                      defaultMessage="Summary"
                      id="WXpOMg"
                    />
                  }
                />
              )}
              <DrawerLink
                href={routes.exports(router.query)}
                active={router.asPath.startsWith(routes.exports(router.query))}
                icon="description"
                title={
                  <FormattedMessage
                    description="Sidebar > Nav link"
                    defaultMessage="Exports"
                    id="Tb48LW"
                  />
                }
              />
              <DrawerLink
                href={routes.account(router.query)}
                active={router.asPath.startsWith(routes.account(router.query))}
                icon="accountCircle"
                title={
                  <FormattedMessage
                    description="Sidebar > Nav link"
                    defaultMessage="Account"
                    id="EgPL4Y"
                  />
                }
              />
              <DrawerLink
                href={routes.help(router.query)}
                icon="help"
                external
                title={
                  <FormattedMessage
                    description="Sidebar > Nav link"
                    defaultMessage="Help Centre"
                    id="egaxme"
                  />
                }
              />
              <DrawerLink
                onClick={logout}
                icon="logout"
                title={
                  <FormattedMessage
                    description="Sidebar > Nav link"
                    defaultMessage="Log out"
                    id="/gvWbH"
                  />
                }
                dataAttribues={{ cy: "logout-button" }}
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default MobileNavigation;
