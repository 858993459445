import Image from "next/image";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import Button from "@/components/Form/Button";
import ContactSupportIcon from "@/components/Icons/ContactSupportIcon";
import A from "@/components/Typography/A";
import { H3 } from "@/components/Typography/H";
import { Images } from "@/components/Utils/Images";

function Default404() {
  const router = useRouter();

  return (
    <div className="mt-40 text-center">
      <Image
        src={Images.notFoundPage.src}
        alt={Images.notFoundPage.alt}
        width={Images.notFoundPage.width}
        height={Images.notFoundPage.height}
      />
      <H3 color="blueGray600" className="mx-auto mt-10 text-lg font-medium max-w-84">
        <FormattedMessage
          defaultMessage="Sorry we couldn’t find that page. :("
          id="qQfXYE"
          description="404 Not Found Page Text"
        />
      </H3>
      <Button className="mx-auto mt-8" onClick={() => router.back()}>
        <FormattedMessage
          defaultMessage="Take me back"
          id="DiSvK3"
          description="404 Not Found Page > Take me back Button"
        />
      </Button>
      <A
        className="flex items-center justify-center mt-5 text-sm text-primaryBlue500"
        href="https://www.fonoa.com/contact-us"
      >
        <ContactSupportIcon className="mr-2" />
        <FormattedMessage
          defaultMessage="Contact Us"
          id="/neEwD"
          description="404 Not Found Page > Contact Us Link"
        />
      </A>
    </div>
  );
}

export default Default404;
