import classNames from "classnames";
import { ReactNode } from "react";

import { convertDataAttributes, DataAttributes } from "@/components/Utils/DataAttributes";
import { getSpacingClass, SpacingProps } from "@/components/Utils/Spacing";

interface HProps extends SpacingProps {
  children: ReactNode;
  className?: string;
  weight?: "light" | "regular" | "medium" | "bold";
  leading?: number | string;
  color?: string;
  dataAttributes?: DataAttributes;
}

const fontWeights = {
  light: "font-light",
  regular: "font-normal",
  medium: "font-medium",
  bold: "font-bold",
};

export const H1 = ({
  children,
  className,
  color,
  weight,
  leading,
  spacing,
  dataAttributes = {},
}: HProps) => {
  const dataAttr = convertDataAttributes(dataAttributes);
  const spacingClass = getSpacingClass(spacing);

  return (
    <h1
      {...dataAttr}
      className={classNames(
        `${color || "text-default"} p-0 m-0 text-4xl leading-${leading || "10"}`,
        weight && fontWeights[weight],
        spacingClass,
        className
      )}
    >
      {children}
    </h1>
  );
};

export const H2 = ({ children, className, color, weight, leading, spacing }: HProps) => {
  const spacingClass = getSpacingClass(spacing);
  return (
    <h2
      className={classNames(
        `${color || "text-default"} p-0 m-0 text-2xl leading-${leading || "8"}`,
        weight && fontWeights[weight],
        className,
        spacingClass
      )}
    >
      {children}
    </h2>
  );
};

export const H3 = ({
  children,
  className,
  color,
  weight,
  leading,
  spacing,
  dataAttributes = {},
}: HProps) => {
  const dataAttr = convertDataAttributes(dataAttributes);

  const spacingClass = getSpacingClass(spacing);
  return (
    <h3
      {...dataAttr}
      className={classNames(
        `${color || "text-default"} p-0 m-0 text-xl leading-${leading || "8"}`,
        weight && fontWeights[weight],
        className,
        spacingClass
      )}
    >
      {children}
    </h3>
  );
};

export const H4 = ({ children, className, color, weight, leading, spacing }: HProps) => {
  const spacingClass = getSpacingClass(spacing);
  return (
    <h4
      className={classNames(
        `${color || "text-default"} p-0 m-0 text-lg leading-${leading || "6"}`,
        weight && fontWeights[weight],
        className,
        spacingClass
      )}
    >
      {children}
    </h4>
  );
};

export const H5 = ({ children, className, color, weight, leading, spacing }: HProps) => {
  const spacingClass = getSpacingClass(spacing);
  return (
    <h5
      className={classNames(
        `${color || "text-default"} p-0 m-0 lg:text-base text-sm leading-${leading || "6"}`,
        weight && fontWeights[weight],
        className,
        spacingClass
      )}
    >
      {children}
    </h5>
  );
};

export const H6 = ({ children, className, color, weight, leading, spacing }: HProps) => {
  const spacingClass = getSpacingClass(spacing);
  return (
    <h6
      className={classNames(
        `${color || "text-default"} p-0 m-0 text-xs leading-${leading || "4"}`,
        weight && fontWeights[weight],
        className,
        spacingClass
      )}
    >
      {children}
    </h6>
  );
};
