interface LogoProps {
  className?: string;
  width?: number;
  fill?: string;
  fillText?: string;
  showDemo?: boolean;
}

export default function Logo({
  className,
  width = 88,
  fill,
  fillText,
  showDemo = false,
}: LogoProps) {
  return (
    <>
      <svg
        width={width}
        height={width / 5}
        className={"inline-block " + className}
        viewBox="0 0 88 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.17786 5.82008C6.44168 5.82008 7.4662 4.79556 7.4662 3.53174C7.4662 2.26793 6.44168 1.24341 5.17786 1.24341C3.91405 1.24341 2.88953 2.26793 2.88953 3.53174C2.88953 4.79556 3.91405 5.82008 5.17786 5.82008Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M11.1283 5.82008C12.3921 5.82008 13.4166 4.79556 13.4166 3.53174C13.4166 2.26793 12.3921 1.24341 11.1283 1.24341C9.86449 1.24341 8.83997 2.26793 8.83997 3.53174C8.83997 4.79556 9.86449 5.82008 11.1283 5.82008Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M17.0787 5.82008C18.3426 5.82008 19.3671 4.79556 19.3671 3.53174C19.3671 2.26793 18.3426 1.24341 17.0787 1.24341C15.8149 1.24341 14.7904 2.26793 14.7904 3.53174C14.7904 4.79556 15.8149 5.82008 17.0787 5.82008Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M5.17786 11.7706C6.44168 11.7706 7.4662 10.7461 7.4662 9.48231C7.4662 8.21849 6.44168 7.19397 5.17786 7.19397C3.91405 7.19397 2.88953 8.21849 2.88953 9.48231C2.88953 10.7461 3.91405 11.7706 5.17786 11.7706Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M11.1283 11.7706C12.3921 11.7706 13.4166 10.7461 13.4166 9.48231C13.4166 8.21849 12.3921 7.19397 11.1283 7.19397C9.86449 7.19397 8.83997 8.21849 8.83997 9.48231C8.83997 10.7461 9.86449 11.7706 11.1283 11.7706Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M17.0788 10.6271C17.7111 10.6271 18.2236 10.1145 18.2236 9.48223C18.2236 8.84996 17.7111 8.3374 17.0788 8.3374C16.4465 8.3374 15.934 8.84996 15.934 9.48223C15.934 10.1145 16.4465 10.6271 17.0788 10.6271Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M5.17786 17.7211C6.44168 17.7211 7.4662 16.6966 7.4662 15.4327C7.4662 14.1689 6.44168 13.1444 5.17786 13.1444C3.91405 13.1444 2.88953 14.1689 2.88953 15.4327C2.88953 16.6966 3.91405 17.7211 5.17786 17.7211Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M11.1283 16.5776C11.7606 16.5776 12.2732 16.0651 12.2732 15.4328C12.2732 14.8005 11.7606 14.288 11.1283 14.288C10.4961 14.288 9.98352 14.8005 9.98352 15.4328C9.98352 16.0651 10.4961 16.5776 11.1283 16.5776Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M17.0788 16.5776C17.7111 16.5776 18.2236 16.0651 18.2236 15.4328C18.2236 14.8005 17.7111 14.288 17.0788 14.288C16.4465 14.288 15.934 14.8005 15.934 15.4328C15.934 16.0651 16.4465 16.5776 17.0788 16.5776Z"
          fill={fill || "#55D287"}
        />
        <path
          d="M32.3656 8.83616H28.5837V17.7H26.1611V8.83616H23.9438V6.64388H26.1598V4.774C26.156 4.24714 26.2278 3.72246 26.373 3.21598C26.5041 2.75175 26.7324 2.32069 27.0428 1.95141C27.3587 1.5856 27.7532 1.29591 28.1968 1.10397C28.7248 0.883714 29.2934 0.77744 29.8654 0.792107C30.4469 0.782496 31.0267 0.860119 31.5852 1.02239C32.0609 1.16906 32.5179 1.37043 32.9472 1.62243L32.0932 3.65417C31.785 3.48261 31.4601 3.34327 31.1234 3.23835C30.769 3.12486 30.3993 3.06672 30.0272 3.06597C29.5579 3.06597 29.2 3.21203 28.9535 3.50416C28.7069 3.79629 28.5837 4.21957 28.5837 4.774V6.64388H32.3656V8.83616Z"
          fill={fillText || fill || "black"}
        />
        <path
          d="M39.2358 6.35442C40.0468 6.34725 40.8513 6.49833 41.6044 6.79919C42.3093 7.08136 42.9518 7.49941 43.4954 8.02955C44.0295 8.55433 44.4532 9.18071 44.7415 9.8718C45.3416 11.3525 45.3416 13.0086 44.7415 14.4893C44.4535 15.1805 44.0298 15.807 43.4954 16.3315C42.9524 16.8589 42.3113 17.2746 41.6084 17.5553C40.0827 18.1483 38.3902 18.1483 36.8646 17.5553C36.162 17.2722 35.5228 16.8522 34.9842 16.3197C34.4522 15.7942 34.0303 15.1679 33.7433 14.4774C33.1432 12.9968 33.1432 11.3406 33.7433 9.85995C34.0307 9.16967 34.4525 8.54344 34.9842 8.01771C35.5239 7.48946 36.163 7.07351 36.8646 6.79393C37.6188 6.49436 38.4243 6.34506 39.2358 6.35442V6.35442ZM39.2358 8.62828C38.7655 8.62549 38.2992 8.7149 37.8633 8.89146C37.4521 9.05653 37.079 9.30419 36.7672 9.61915C36.453 9.9406 36.2056 10.3212 36.0395 10.739C35.6851 11.6637 35.6851 12.6868 36.0395 13.6116C36.2056 14.0293 36.453 14.4099 36.7672 14.7314C37.079 15.0463 37.4521 15.294 37.8633 15.4591C38.7461 15.8047 39.7268 15.8047 40.6096 15.4591C41.0262 15.294 41.4045 15.0454 41.7214 14.7286C42.0382 14.4118 42.2868 14.0334 42.4519 13.6168C42.8045 12.6918 42.8045 11.6693 42.4519 10.7442C42.2868 10.3277 42.0382 9.9493 41.7214 9.63247C41.4045 9.31563 41.0262 9.06703 40.6096 8.90199C40.174 8.72172 39.7073 8.62872 39.2358 8.62828V8.62828Z"
          fill={fillText || fill || "black"}
        />
        <path
          d="M53.3974 6.35453C54.0142 6.34793 54.6267 6.45776 55.2028 6.67824C55.7336 6.88158 56.2183 7.18945 56.6279 7.58357C57.0313 7.97724 57.3478 8.45105 57.5569 8.97447C57.7814 9.53995 57.8932 10.1439 57.8859 10.7522V17.7002H55.462V11.5484C55.462 10.6185 55.237 9.90305 54.787 9.40213C54.3369 8.90122 53.7057 8.6512 52.8934 8.65208C52.5158 8.64943 52.1407 8.71358 51.7854 8.84156C51.4478 8.96387 51.1391 9.15473 50.8788 9.40213C50.6113 9.66293 50.4011 9.97665 50.2616 10.3233C50.1019 10.7208 50.0236 11.1463 50.0313 11.5747V17.7041H47.6035V6.6506H49.9984V7.99413C50.2039 7.68978 50.4551 7.41901 50.7432 7.19144C51.0053 6.98571 51.2945 6.81701 51.6025 6.69008C51.8937 6.56805 52.1979 6.47975 52.5092 6.4269C52.8027 6.37843 53.0998 6.35422 53.3974 6.35453V6.35453Z"
          fill={fillText || fill || "black"}
        />
        <path
          d="M66.126 6.35451C66.937 6.34704 67.7416 6.49813 68.4946 6.79928C69.1993 7.08108 69.8415 7.49919 70.3843 8.02964C70.9188 8.55408 71.3426 9.18055 71.6304 9.87189C72.2305 11.3526 72.2305 13.0087 71.6304 14.4894C71.3429 15.1809 70.9191 15.8074 70.3843 16.3316C69.8417 16.8594 69.2005 17.2752 68.4973 17.5554C66.9736 18.147 65.2837 18.147 63.7601 17.5554C63.0581 17.2758 62.4186 16.8599 61.8783 16.3316C61.3468 15.8058 60.925 15.1796 60.6375 14.4894C60.0374 13.0087 60.0374 11.3526 60.6375 9.87189C60.9253 9.18186 61.3471 8.55571 61.8783 8.02964C62.4186 7.50139 63.0581 7.08545 63.7601 6.80586C64.5118 6.50279 65.3155 6.34947 66.126 6.35451V6.35451ZM66.126 8.62837C65.6553 8.62572 65.1886 8.71512 64.7522 8.89155C64.3413 9.05728 63.9684 9.30486 63.6561 9.61924C63.3418 9.94042 63.0949 10.3211 62.9297 10.7391C62.5753 11.6638 62.5753 12.6869 62.9297 13.6117C63.0949 14.0296 63.3418 14.4103 63.6561 14.7315C63.9684 15.0459 64.3413 15.2934 64.7522 15.4592C65.6355 15.8048 66.6166 15.8048 67.4998 15.4592C67.9147 15.2928 68.2913 15.0437 68.6067 14.7269C68.9221 14.4102 69.1696 14.0325 69.3342 13.6169C69.6886 12.6922 69.6886 11.6691 69.3342 10.7443C69.1696 10.3287 68.9221 9.95106 68.6067 9.63431C68.2913 9.31757 67.9147 9.06843 67.4998 8.90208C67.0642 8.72204 66.5974 8.62906 66.126 8.62837V8.62837Z"
          fill={fillText || fill || "black"}
        />
        <path
          d="M85.1104 17.7H82.6983V16.3722C82.3131 16.8645 81.8243 17.2661 81.2667 17.5486C80.631 17.8568 79.9305 18.0075 79.2244 17.9881C78.4933 17.9953 77.7684 17.8537 77.0938 17.5718C76.4192 17.29 75.809 16.8737 75.3004 16.3485C74.7907 15.818 74.3909 15.1919 74.124 14.5063C73.8326 13.762 73.6874 12.9685 73.6963 12.1693C73.6872 11.37 73.8324 10.5765 74.124 9.83224C74.391 9.1467 74.7908 8.52064 75.3004 7.99C75.8094 7.46551 76.4197 7.05003 77.0943 6.76884C77.7689 6.48765 78.4936 6.34663 79.2244 6.35434C79.8913 6.32898 80.5546 6.46349 81.1589 6.74665C81.7632 7.02982 82.2911 7.45342 82.6983 7.9821V6.64384H85.1104V17.7ZM79.5126 8.6282C79.0305 8.61999 78.5523 8.71652 78.1111 8.91112C77.7133 9.08893 77.3573 9.34853 77.0663 9.67302C76.7759 10.0017 76.5524 10.3838 76.4084 10.7981C76.1015 11.6878 76.1015 12.6547 76.4084 13.5444C76.5524 13.9586 76.7759 14.3408 77.0663 14.6695C77.3573 14.994 77.7133 15.2536 78.1111 15.4314C78.5481 15.6161 79.0173 15.7123 79.4916 15.7143C79.966 15.7163 80.436 15.6242 80.8745 15.4432C81.2731 15.2703 81.6315 15.0167 81.9272 14.6984C82.2255 14.3738 82.4571 13.9938 82.6089 13.5799C82.932 12.6691 82.932 11.6748 82.6089 10.7639C82.4571 10.3496 82.2255 9.96915 81.9272 9.64407C81.6313 9.32605 81.2729 9.0725 80.8745 8.89928C80.4446 8.7143 79.9805 8.62194 79.5126 8.6282V8.6282Z"
          fill={fillText || fill || "black"}
        />
      </svg>
      {showDemo && (
        <svg
          width={width / 2}
          height={(width / 2 / 48) * 14}
          viewBox="0 0 48 14"
          className="inline-block mt-2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6289 5.38606C10.641 5.92825 10.647 6.40416 10.647 6.81381C10.647 7.22346 10.641 7.69938 10.6289 8.24156C10.6169 9.00062 10.4844 9.68738 10.2313 10.3019C9.97832 10.9043 9.62891 11.4163 9.18312 11.838C8.73733 12.2597 8.20719 12.585 7.59272 12.814C6.97824 13.0308 6.2975 13.1393 5.55049 13.1393H1.30339C1.17086 13.1393 1.0564 13.0911 0.960012 12.9947C0.863624 12.8983 0.81543 12.7838 0.81543 12.6513V0.976309C0.81543 0.843776 0.863624 0.729315 0.960012 0.632927C1.0564 0.536539 1.17086 0.488345 1.30339 0.488345H5.44206C6.18906 0.488345 6.87583 0.602806 7.50235 0.831727C8.14092 1.0486 8.68913 1.36789 9.14697 1.78958C9.60482 2.21128 9.96025 2.72937 10.2133 3.34384C10.4783 3.94627 10.6169 4.62701 10.6289 5.38606ZM8.91203 5.47643C8.89998 5.04268 8.82769 4.62701 8.69515 4.22941C8.57467 3.81976 8.37587 3.4583 8.09875 3.14504C7.82164 2.83178 7.46018 2.58479 7.01439 2.40406C6.56859 2.21128 6.02039 2.11489 5.36977 2.11489H2.53234V11.5127H5.46013C6.11075 11.5127 6.65293 11.4224 7.08668 11.2416C7.53247 11.0489 7.8879 10.7958 8.15297 10.4826C8.41804 10.1693 8.60479 9.81389 8.71323 9.41629C8.83371 9.00664 8.89998 8.58494 8.91203 8.1512C8.91203 7.88613 8.91203 7.65118 8.91203 7.44636C8.92408 7.22949 8.9301 7.02466 8.9301 6.83189C8.9301 6.62706 8.92408 6.42224 8.91203 6.21741C8.91203 6.00054 8.91203 5.75354 8.91203 5.47643Z"
            fill="#55D287"
          />
          <path
            d="M21.0475 11.5127C21.18 11.5127 21.2945 11.5609 21.3908 11.6573C21.4872 11.7537 21.5354 11.8682 21.5354 12.0007V12.6513C21.5354 12.7838 21.4872 12.8983 21.3908 12.9947C21.2945 13.0911 21.18 13.1393 21.0475 13.1393H13.7461C13.6135 13.1393 13.4991 13.0911 13.4027 12.9947C13.3063 12.8983 13.2581 12.7838 13.2581 12.6513V0.976309C13.2581 0.843776 13.3063 0.729315 13.4027 0.632927C13.4991 0.536539 13.6135 0.488345 13.7461 0.488345H20.9029C21.0354 0.488345 21.1499 0.536539 21.2463 0.632927C21.3427 0.729315 21.3908 0.843776 21.3908 0.976309V1.62693C21.3908 1.75946 21.3427 1.87392 21.2463 1.97031C21.1499 2.0667 21.0354 2.11489 20.9029 2.11489H14.975V5.92825H20.5053C20.6378 5.92825 20.7523 5.97644 20.8487 6.07283C20.9451 6.16922 20.9932 6.28368 20.9932 6.41621V7.06683C20.9932 7.19937 20.9451 7.31383 20.8487 7.41021C20.7523 7.5066 20.6378 7.5548 20.5053 7.5548H14.975V11.5127H21.0475Z"
            fill="#55D287"
          />
          <path
            d="M29.5741 8.13312L33.2248 1.06667C33.2971 0.922092 33.3935 0.789558 33.514 0.669072C33.6465 0.548587 33.8152 0.488345 34.02 0.488345H34.7068C34.8393 0.488345 34.9538 0.536539 35.0502 0.632927C35.1466 0.729315 35.1948 0.843776 35.1948 0.976309V12.6513C35.1948 12.7838 35.1466 12.8983 35.0502 12.9947C34.9538 13.0911 34.8393 13.1393 34.7068 13.1393H33.9658C33.8333 13.1393 33.7188 13.0911 33.6224 12.9947C33.5261 12.8983 33.4779 12.7838 33.4779 12.6513V4.08483L30.6585 9.52473C30.5621 9.70546 30.4597 9.85004 30.3513 9.95847C30.2428 10.0549 30.0982 10.1031 29.9175 10.1031H29.2308C29.05 10.1031 28.9054 10.0549 28.797 9.95847C28.6886 9.85004 28.5862 9.70546 28.4898 9.52473L25.6704 4.08483V12.6513C25.6704 12.7838 25.6222 12.8983 25.5258 12.9947C25.4295 13.0911 25.315 13.1393 25.1825 13.1393H24.4415C24.3089 13.1393 24.1945 13.0911 24.0981 12.9947C24.0017 12.8983 23.9535 12.7838 23.9535 12.6513V0.976309C23.9535 0.843776 24.0017 0.729315 24.0981 0.632927C24.1945 0.536539 24.3089 0.488345 24.4415 0.488345H25.1282C25.3331 0.488345 25.4957 0.548587 25.6162 0.669072C25.7487 0.789558 25.8511 0.922092 25.9234 1.06667L29.5741 8.13312Z"
            fill="#55D287"
          />
          <path
            d="M37.8324 5.20534C37.8445 4.53062 37.9589 3.89807 38.1758 3.3077C38.4047 2.70527 38.73 2.18718 39.1517 1.75344C39.5734 1.30764 40.0915 0.958237 40.706 0.705219C41.3204 0.440151 42.0253 0.307617 42.8205 0.307617C43.6157 0.307617 44.3205 0.440151 44.935 0.705219C45.5495 0.958237 46.0676 1.30764 46.4893 1.75344C46.911 2.18718 47.2302 2.70527 47.4471 3.3077C47.676 3.89807 47.7965 4.53062 47.8086 5.20534C47.8447 6.27765 47.8447 7.34997 47.8086 8.42229C47.7845 9.097 47.658 9.73558 47.429 10.338C47.2122 10.9284 46.8929 11.4465 46.4712 11.8923C46.0495 12.3381 45.5314 12.6875 44.9169 12.9405C44.3145 13.1935 43.6157 13.32 42.8205 13.32C42.0253 13.32 41.3204 13.1935 40.706 12.9405C40.1035 12.6875 39.5915 12.3381 39.1698 11.8923C38.7481 11.4465 38.4228 10.9284 38.1939 10.338C37.977 9.73558 37.8565 9.097 37.8324 8.42229C37.7963 7.34997 37.7963 6.27765 37.8324 5.20534ZM46.0917 5.2957C46.0676 4.72942 45.9712 4.23543 45.8025 3.81373C45.6459 3.39204 45.423 3.04263 45.1338 2.76551C44.8446 2.4884 44.5013 2.28357 44.1036 2.15104C43.7181 2.00646 43.2904 1.93417 42.8205 1.93417C42.3506 1.93417 41.9168 2.00646 41.5192 2.15104C41.1337 2.28357 40.7963 2.4884 40.5072 2.76551C40.218 3.04263 39.9891 3.39204 39.8204 3.81373C39.6517 4.23543 39.5614 4.72942 39.5493 5.2957C39.5252 5.83788 39.5132 6.34392 39.5132 6.81381C39.5132 7.2837 39.5252 7.78974 39.5493 8.33193C39.5614 8.8982 39.6517 9.39219 39.8204 9.81389C39.9891 10.2356 40.218 10.585 40.5072 10.8621C40.7963 11.1392 41.1337 11.3501 41.5192 11.4947C41.9168 11.6272 42.3506 11.6935 42.8205 11.6935C43.2904 11.6935 43.7181 11.6272 44.1036 11.4947C44.5013 11.3501 44.8446 11.1392 45.1338 10.8621C45.423 10.585 45.6459 10.2356 45.8025 9.81389C45.9712 9.39219 46.0676 8.8982 46.0917 8.33193C46.1157 7.78974 46.1278 7.2837 46.1278 6.81381C46.1278 6.34392 46.1157 5.83788 46.0917 5.2957Z"
            fill="#55D287"
          />
        </svg>
      )}
    </>
  );
}
