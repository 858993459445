import GA4React from "ga-4-react";
import Router from "next/router";

import config from "@/client/config";
import { getEnvironment } from "@/client/utils";

let ga4react: GA4React;

export async function init(gaTag, debugMode) {
  if (!GA4React.isInitialized() && gaTag && process.browser) {
    ga4react = new GA4React(gaTag, { debug_mode: debugMode });

    try {
      await ga4react.initialize();
      logPageViews();
    } catch (error) {
      // Note: Users having certain ad-blockers stop GA4 from working and currently
      // causes a crash in ga-4-react. This try/catch block prevents that.
      console.error(error);
    }
  }
}

function logPageView() {
  ga4react?.pageview(window.location.pathname);
}

export const setUserProps = async (userProps: Record<string, string>) => {
  const env = getEnvironment();
  if (!ga4react && env !== "local") {
    await init(config.GOOGLE_ANALYTICS_4_CODE(), config.GOOGLE_ANALYTICS_DEBUG_MODE());
  }
  gtag("set", "user_properties", userProps);
  gtag("set", "appVersion", config.APP_VERSION());
};

function logPageViews() {
  logPageView();

  Router.events.on("routeChangeComplete", () => {
    logPageView();
  });
}

export function logEvent(action, category, label?) {
  if (typeof window !== "undefined" && window.gtag) {
    ga4react?.event(action, label, category);
  }
}

export function gtag(action: "event" | string, name: string, params?: any) {
  if (typeof window !== "undefined" && window.gtag) {
    ga4react?.gtag(action, name, params);
  }
}
