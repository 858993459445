import { useRouter } from "next/router";

import Dashboard404 from "@/components/Pages/NotFound/components/Dashboard404";
import Default404 from "@/components/Pages/NotFound/components/Default404";
import { useAuth } from "@/hooks/useAuth";

interface NotFoundProps {
  dashboard?: boolean;
}

function NotFound({ dashboard = false }: NotFoundProps) {
  const auth = useAuth();
  const router = useRouter();

  if ((dashboard || auth?.isLoggedIn) && router.query.country) {
    return <Dashboard404 />;
  }

  return <Default404 />;
}

export default NotFound;
