import classNames from "classnames";

import { defaultIconClassNames, IconProps } from "./Icon";

interface ExtendedIconProps extends IconProps {
  height?: number
  width?: number
}

export default function ArrowRightIcon({
  size = 32,
  height,
  width,
  className = "",
  style,
  onClick
}: ExtendedIconProps) {
  return (
    <svg
      width={width || (size / 2)}
      height={height || size}
      viewBox={`0 0 7 12`}
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      style={style}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.12648 12L1.37224e-08 10.8493L4.74704 6L1.29376e-07 1.15074L1.12648 9.67107e-07L6.31521 5.30047C6.69575 5.6892 6.69575 6.31081 6.31521 6.69954L1.12648 12Z"
      />
    </svg>
  );
}
