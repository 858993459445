import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import { routes } from "@/client/routes";
import SidebarLayout from "@/components/Layouts/SidebarLayout";
import A from "@/components/Typography/A";
import P from "@/components/Typography/P";
import { useAuth } from "@/hooks/useAuth";

import ErrorImage from "./ErrorImage";
import ErrorTitle from "./ErrorTitle";

function DashboardError({ errorCode }) {
  const router = useRouter();
  const auth = useAuth();

  const reloadThePage = (
    <A className="text-primaryBlue500" onClick={router.reload}>
      <FormattedMessage
        description="Dashboard Error > Reload the page"
        defaultMessage="try reloading the web app"
        id="1dp1n8"
      />
    </A>
  );

  const returnToDashboard = (
    <A
      className="text-primaryBlue500"
      href={routes.dashboard({
        country: auth?.user?.country,
      })}
    >
      <FormattedMessage
        description="Dashboard Error > Return to dashboard"
        defaultMessage="return to the Dashboard"
        id="kGEzTq"
      />
    </A>
  );

  const contactUsLink = (
    <A className="text-primaryBlue500" href="https://www.fonoa.com/contact-us">
      <FormattedMessage
        description="Dashboard Error > Contact us"
        defaultMessage="contact us"
        id="MX/3v8"
      />
    </A>
  );

  return (
    <SidebarLayout windowTitle="Fonoa Dashboard">
      <div className="mt-40 text-center">
        <ErrorImage />
        <ErrorTitle errorCode={errorCode} />
        <P className="mx-auto mt-4 text-sm max-w-80 text-blueGray600">
          <FormattedMessage
            description="Dashboard Error > API error message"
            defaultMessage="Our API is reliable but mishaps do happen. You can {reloadThePage}, or {returnToDashboard}"
            id="teJiii"
            values={{ reloadThePage, returnToDashboard }}
          />
        </P>
        <P className="mx-auto mt-4 text-sm max-w-80 text-blueGray600">
          <FormattedMessage
            description="Dashboard Error > Contact us message"
            defaultMessage="If the problem persists, please {contactUsLink} for support."
            id="ir3KAL"
            values={{ contactUsLink }}
          />
        </P>
      </div>
    </SidebarLayout>
  );
}

export default DashboardError;
