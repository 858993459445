const config = {
  APP_VERSION: () => process.env.NEXT_PUBLIC_APP_VERSION || "dev",
  APP_ENV: () => process.env.NEXT_PUBLIC_APP_ENV || "local",

  //
  // Google analytics
  //
  GOOGLE_ANALYTICS_4_CODE: () => process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_CODE || "G-E4Y3DC0BGD",
  GOOGLE_ANALYTICS_DEBUG_MODE: () =>
    `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_DEBUG_MODE}`.toLowerCase() === "false"
      ? false
      : true,

  //
  // Hotjar analytics
  //
  HOTJAR_ID: () => Number(process.env.NEXT_PUBLIC_HOTJAR_ID || 2893757),
  HOTJAR_SNIPPET_VERSION: () => Number(process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION || 6),

  //
  // Sentry monitoring
  //
  SENTRY_DSN: () => process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_TRACE_RATE: () => 0.02, // Track 2% of Transaction events - https://docs.sentry.io/platforms/javascript/configuration/sampling/#configuring-the-transaction-sample-rate

  //
  // Auth0
  //
  AUTH0_DOMAIN: () => process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  AUTH0_PUBLIC_CLIENT_ID: () => process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  AUTH0_CONNECTION: () => process.env.NEXT_PUBLIC_AUTH0_CONNECTION,
  AUTH0_CALLBACK_URL: () => process.env.NEXT_PUBLIC_AUTH0_CALLBACK_URL,

  //
  // Mocks
  //
  MOCK_NOTIFICATION_BANNER: () => process.env.NEXT_PUBLIC_MOCK_NOTIFICATION_BANNER,

  //
  // Romania
  //
  RO_OAUTH_CLIENT_ID: () => process.env.NEXT_PUBLIC_RO_OAUTH_CLIENT_ID || "",
  RO_OAUTH_REDIRECT_URI: () => process.env.NEXT_PUBLIC_RO_OAUTH_REDIRECT_URI || "",
};

export default config;
