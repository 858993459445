import "@/styles/autosuggest.css";
import "@/styles/datepicker.css";
import "@/styles/forms.css";
import "@/styles/globals.css";
import "@/styles/react-colorful.scss";
import "@/styles/toastify/main.scss";
import "@/styles/tooltip.css";
import "@formatjs/intl-displaynames/locale-data/en";
import "@formatjs/intl-displaynames/polyfill";
import "intersection-observer";
import "react-day-picker/lib/style.css";
import "moment-timezone";

import * as Sentry from "@sentry/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { hotjar } from "react-hotjar";
import { IntlProvider } from "react-intl";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { init } from "utils/ga";

import clientConfig from "@/client/config";
import setupAxiosInterceptors from "@/client/setupAxiosInterceptors";
import { getEnvironment, queryClient } from "@/client/utils";
import ErrorProxy from "@/components/Pages/Error/ErrorProxy";
import { ProvideAuth } from "@/hooks/useAuth";
import { SelectedLanguageProvider, useSelectedLanguage } from "@/hooks/useSelectedLanguage";
import { SupportedBrowsers } from "@/hooks/useSupportedBrowsers";

setupAxiosInterceptors();

function SafeHydrate({ children }) {
  return <div suppressHydrationWarning>{typeof window === "undefined" ? null : children}</div>;
}

const App = (props) => {
  const router = useRouter();

  if (!router.isReady) {
    return null;
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorProxy />} showDialog>
      <ProvideAuth>
        <SelectedLanguageProvider>
          <Head>
            <meta name="robots" content="noindex,nofollow" />
          </Head>
          <Children {...props} />
        </SelectedLanguageProvider>
      </ProvideAuth>
    </Sentry.ErrorBoundary>
  );
};

const Children = ({ Component, pageProps }) => {
  const environment = getEnvironment();

  const { selectedLanguage, translations } = useSelectedLanguage();
  const preferredLocaleFromBrowserSettings =
    typeof navigator !== "undefined" ? navigator.language : "en";

  console.debug({ GIT_SHA: process.env.NEXT_PUBLIC_GIT_SHA?.substring(0, 7) });

  useEffect(() => {
    if (environment !== "local") {
      // Google Analytics
      init(clientConfig.GOOGLE_ANALYTICS_4_CODE(), clientConfig.GOOGLE_ANALYTICS_DEBUG_MODE());
    }

    if (environment === "production") {
      // Hotjar
      hotjar.initialize(clientConfig.HOTJAR_ID(), clientConfig.HOTJAR_SNIPPET_VERSION());
    }

    console.debug(`The app is running in the ${clientConfig.APP_ENV()} environment`);
  }, []);

  return (
    <IntlProvider
      locale={preferredLocaleFromBrowserSettings}
      defaultLocale="en"
      textComponent={Fragment}
      messages={translations}
      onError={(error) => {
        if (error.code === "MISSING_TRANSLATION") {
          if (environment === "production" && selectedLanguage !== "en") {
            Sentry.captureException(error);
          }
        } else if (environment === "local") {
          console.error(error);
        }
      }}
    >
      <SupportedBrowsers>
        <QueryClientProvider client={queryClient}>
          <SafeHydrate>
            <Component {...pageProps} />
            <ToastContainer />
          </SafeHydrate>
        </QueryClientProvider>
      </SupportedBrowsers>
    </IntlProvider>
  );
};

export default App;
