import classNames from "classnames";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CloseIcon({
  size = 32,
  className = "",
  style,
  ...props
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 16 16`}
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88889 8L3 4.11111L4.11111 3L8 6.88889L11.8889 3L13 4.11111L9.11111 8L13 11.8889L11.8889 13L8 9.11111L4.11111 13L3 11.8889L6.88889 8Z"
      />
    </svg>
  );
}
