import Image from "next/image";
import { FormattedMessage } from "react-intl";

import A from "@/components/Typography/A";
import { H3 } from "@/components/Typography/H";
import P from "@/components/Typography/P";

const SuggestBrowser = () => (
  <div className="flex flex-col items-center w-full bg-white">
    <div className="flex flex-col justify-center flex-1 max-w-2xl px-4 py-16 text-center">
      <Image src="/img/support-browsers.svg" alt="" width="240" height="154" />
      <H3 color="blueGray600" className="mt-8 text-lg font-medium">
        <FormattedMessage
          defaultMessage="Please update your browser."
          description="Suggest browser"
          id="VS+Za2"
        />
      </H3>
      <P color="blueGray600" className="mt-4 text-sm font-normal">
        <FormattedMessage
          defaultMessage="Your current browser does not support Fonoa app. Please make sure your browser is fully updated or try a suggested browser below."
          id="5rouy1"
          description="Suggest browser"
        />
      </P>
      <P color="blueGray600" className="mt-6 text-sm font-normal">
        <FormattedMessage
          defaultMessage="For more information please visit our {supportPageLink} or {contactUsLink} for support."
          id="mntBLK"
          description="Suggest browser"
          values={{
            supportPageLink: (
              <A className="inline mx-1 text-primaryBlue500" href="/">
                <FormattedMessage
                  defaultMessage="support page"
                  description="support page"
                  id="AuqtV+"
                />
              </A>
            ),
            contactUsLink: (
              <A
                className="inline mx-1 text-primaryBlue500"
                href="https://www.fonoa.com/contact-us"
              >
                <FormattedMessage
                  defaultMessage="contact us"
                  description="Contact us"
                  id="oVg83r"
                />
              </A>
            ),
          }}
        />
      </P>
      <div className="flex flex-wrap justify-center mt-6 lg:mt-10">
        <A className="flex flex-col px-4 mt-5" href="https://www.google.com/chrome/">
          <Image src="/img/browsers/chrome.svg" alt="" width="32" height="32" />
          {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */}
          <P color="primaryBlue500" className="mt-3 text-sm font-medium">
            Google Chrome
          </P>
          <p className="text-xs text-blueGray600">
            <FormattedMessage
              defaultMessage="Version"
              description="Suggest browser > version"
              id="yDS5E1"
            />
            {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */} 55+
          </p>
        </A>
        <A
          className="flex flex-col px-4 mt-5"
          href="https://support.microsoft.com/en-us/windows/internet-explorer-downloads-d49e1f0d-571c-9a7b-d97e-be248806ca70"
        >
          <Image src="/img/browsers/edge.svg" alt="" width="32" height="32" />
          {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */}
          <P color="primaryBlue500" className="mt-3 text-sm font-medium">
            Microsoft Edge
          </P>
          <p className="text-xs text-blueGray600">
            <FormattedMessage
              defaultMessage="Version"
              description="Suggest browser > version"
              id="yDS5E1"
            />
            {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */} 15+
          </p>
        </A>
        <A className="flex flex-col px-4 mt-5" href="https://www.mozilla.org/en-US/firefox/new/">
          <Image src="/img/browsers/firefox.svg" alt="" width="32" height="32" />
          {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */}
          <P color="primaryBlue500" className="mt-3 text-sm font-medium">
            Mozilla Firefox
          </P>
          <p className="text-xs text-blueGray600">
            <FormattedMessage
              defaultMessage="Version"
              description="Suggest browser > version"
              id="yDS5E1"
            />
            {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */} 52+
          </p>
        </A>
        <A className="flex flex-col px-4 mt-5" href="https://www.apple.com/safari/">
          <Image src="/img/browsers/safari.svg" alt="" width="32" height="32" />
          {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */}
          <P color="primaryBlue500" className="mt-3 text-sm font-medium">
            Apple Safari
          </P>
          <p className="text-xs text-blueGray600">
            <FormattedMessage
              defaultMessage="Version"
              description="Suggest browser > version"
              id="yDS5E1"
            />
            {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */} 11+
          </p>
        </A>
        <A className="flex flex-col px-4 mt-5" href="https://www.opera.com/download">
          <Image src="/img/browsers/opera.svg" alt="" width="32" height="32" />
          {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */}
          <P color="primaryBlue500" className="mt-3 text-sm font-medium">
            Opera
          </P>
          <p className="text-xs text-blueGray600">
            <FormattedMessage
              defaultMessage="Version"
              description="Suggest browser > version"
              id="yDS5E1"
            />
            {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */} 42+
          </p>
        </A>
      </div>
    </div>
  </div>
);

export default SuggestBrowser;
