import classNames from "classnames";
import Link from "next/link";
import { ComponentType, HTMLAttributes, ReactNode } from "react";

import { noop } from "@/client/utils";
import { IconProps } from "@/components/Icons/Icon";
import OutsideIcon from "@/components/Icons/OutsideIcon";
import { getSpacingClass, SpacingProps } from "@/components/Utils/Spacing";
import getIcon, { IconType } from "@/hooks/getIcon";

interface AProps extends SpacingProps {
  children: ReactNode;
  href?: any;
  className?: string;
  external?: boolean;
  basic?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  leftIcon?: IconType;
  rightIcon?: IconType;
  iconSize?: number;
  leftIconProps?: Omit<IconProps, "size">;
  rightIconProps?: Omit<IconProps, "size">;
  // Diff here is that onClick affect routing behavior and change hrefLocation property, handleClick dont affect anything
  handleClick?: () => void;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  hideExternalIcon?: boolean;
}

export default function A({
  children,
  className,
  href,
  external,
  onClick,
  handleClick = noop,
  basic = false,
  spacing,
  leftIcon,
  rightIcon,
  iconSize,
  leftIconProps = {},
  rightIconProps = {},
  containerProps = {},
  hideExternalIcon = false,
}: AProps) {
  const LeftIconComponent: ComponentType<IconProps> | null = getIcon(leftIcon);
  const RightIconComponent: ComponentType<IconProps> | null = getIcon(rightIcon);
  const { className: containerClassName, ...otherContainerProps } = containerProps;
  const hrefLocation = !onClick ? href : "#";
  const safeUrl = typeof hrefLocation === "object" ? "#" : hrefLocation;
  const classes = basic ? "" : "text-info group-hover:text-infoHover active:text-infoActive";
  const spacingClass = getSpacingClass(spacing);

  if (leftIcon || rightIcon) {
    return (
      <div
        className={classNames("flex items-center group cursor-pointer", containerClassName)}
        {...otherContainerProps}
      >
        {LeftIconComponent && (
          <LeftIconComponent
            {...leftIconProps}
            className={classNames("align-middle mr-2", leftIconProps.className)}
            size={iconSize}
          />
        )}
        <Link href={hrefLocation}>
          <a
            href={safeUrl}
            onClick={(e) => {
              if (typeof onClick === "function") {
                e.preventDefault();
                onClick(e);
              }
              handleClick();
            }}
            className={classNames(classes, className, spacingClass)}
            target={external ? "_blank" : undefined}
            rel="noreferrer"
          >
            {children}
            {external && !hideExternalIcon && !rightIcon && (
              <OutsideIcon className="ml-1" size={8} />
            )}
          </a>
        </Link>

        {RightIconComponent && (
          <RightIconComponent
            {...rightIconProps}
            className={classNames("align-middle ml-2", rightIconProps.className)}
            size={iconSize}
          />
        )}
      </div>
    );
  }

  return (
    <Link href={hrefLocation}>
      <a
        href={safeUrl}
        onClick={(e) => {
          if (typeof onClick === "function") {
            e.preventDefault();
            onClick(e);
          }
          handleClick();
        }}
        className={classNames(classes, className, spacingClass)}
        target={external || rightIcon ? "_blank" : undefined}
        rel="noreferrer"
      >
        {children}
        {external && !hideExternalIcon && <OutsideIcon className="ml-1" size={8} />}
      </a>
    </Link>
  );
}
