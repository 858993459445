import { FormattedMessage } from "react-intl";

import { H3 } from "@/components/Typography/H";

export default function ErrorTitle({ errorCode }) {
  return (
    <H3 color="blueGray600" className="mx-auto mt-10 text-lg font-medium max-w-84">
      <FormattedMessage
        description="Dashboard Error > Error title"
        defaultMessage="Error {errorCode}"
        id="eEB26O"
        values={{ errorCode }}
      />
    </H3>
  );
}
