import classNames from "classnames";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { noop } from "@/client/utils";
import { IconProps } from "@/components/Icons/Icon";
import Badge from "@/components/Labels/Badge";
import A from "@/components/Typography/A";
import P from "@/components/Typography/P";
import getIcon, { IconType } from "@/hooks/getIcon";

export type SizeVariant = "Regular" | "Small";
export type StatusVariant = "Default" | "Active" | "Hover" | "Disabled";

const TEXT_SIZE_VARIANT_MAPS: Record<SizeVariant, string> = {
  Regular: "text-sm",
  Small: "text-xs",
};

const TEXT_DARK_COLOR_VARIANT_MAPS: Record<StatusVariant, string> = {
  Default: "dark:text-white",
  Hover: "dark:text-white",
  Active: "dark:text-fonoaGreen dark:bg-transparent",
  Disabled: "dark:text-blueGray600",
};

const TEXT_LIGHT_COLOR_VARIANT_MAPS: Record<StatusVariant, string> = {
  Default: "text-blueGray700",
  Hover: "text-blueGray700",
  Active: "text-primaryBlue500 bg-primaryBlue25",
  Disabled: "text-blueGray300",
};

interface SidebarLinkProps {
  className?: string;
  icon?: IconType;
  title: string | ReactNode;
  label?: ReactNode;
  href?: string;
  hasAccess?: boolean;
  size?: SizeVariant;
  status?: StatusVariant;
  withLink?: string;
  withNotification?: number;
  handleClick?: () => void;
  iconProps?: IconProps;
  external?: boolean;
}

export default function SidebarLink({
  icon,
  title,
  label,
  className,
  href,
  hasAccess = true,
  size = "Regular",
  status = "Default",
  withLink,
  withNotification,
  handleClick = noop,
  iconProps,
  external,
}: SidebarLinkProps) {
  const Icon = getIcon(icon);

  if (!hasAccess) {
    return null;
  }

  return (
    <A
      href={href}
      basic
      className={classNames(
        "flex items-center group w-64",
        TEXT_SIZE_VARIANT_MAPS[size],
        TEXT_DARK_COLOR_VARIANT_MAPS[status],
        TEXT_LIGHT_COLOR_VARIANT_MAPS[status],
        {
          "h-8": size === "Regular",
          "h-7": size === "Small",
          "cursor-pointer": status !== "Disabled",
          "cursor-disabled": status === "Disabled",
        },
        className
      )}
      handleClick={handleClick}
      external={external}
      hideExternalIcon
    >
      <div
        style={{ width: 3 }}
        className={classNames("h-full mr-6", {
          "mr-12": !Icon,
          "bg-primaryBlue500 dark:bg-fonoaGreen": status === "Hover",
          "group-hover:bg-primaryBlue500 dark:group-hover:bg-fonoaGreen": status !== "Disabled",
        })}
      />
      {Icon && (
        <>
          <div className="dark:hidden">
            <Icon size={16} secondColor="#FFFFFF" className="mr-2" {...iconProps} />
          </div>
          <div className="hidden dark:block">
            <Icon size={16} secondColor="#32526A" className="mr-2" {...iconProps} />
          </div>
        </>
      )}
      {label ? (
        <div className="flex items-center space-x-2">
          <P
            type="medium"
            className={classNames("flex-1", {
              "font-medium": status === "Hover",
              "group-hover:font-medium": status !== "Disabled",
            })}
          >
            {title}
          </P>
          <div>{label}</div>
        </div>
      ) : (
        <P
          type="medium"
          className={classNames("flex-1", {
            "font-medium": status === "Hover",
            "group-hover:font-medium": status !== "Disabled",
          })}
        >
          {title}
        </P>
      )}

      {withLink &&
        (status === "Disabled" ? (
          <span
            className={classNames(
              "mr-6 text-xs font-normal",
              TEXT_LIGHT_COLOR_VARIANT_MAPS[status],
              TEXT_DARK_COLOR_VARIANT_MAPS[status]
            )}
          >
            <FormattedMessage defaultMessage="Link" description="Link to the page" id="bOTzac" />
          </span>
        ) : (
          <A href={withLink} className="mr-6 text-xs font-normal">
            <FormattedMessage defaultMessage="Link" description="Link to the page" id="bOTzac" />
          </A>
        ))}
      {withNotification && <Badge className="mr-6">{withNotification}</Badge>}
    </A>
  );
}
