import { useRouter } from "next/router";
import { MouseEvent } from "react";
import { FormattedMessage } from "react-intl";

import { routes } from "@/client/routes";
import Avatar from "@/components/Avatar";
import Button from "@/components/Form/Button";
import ArrowDownIcon from "@/components/Icons/ArrowDownIcon";
import ArrowUpIcon from "@/components/Icons/ArrowUpIcon";
import P from "@/components/Typography/P";
import { useAuth } from "@/hooks/useAuth";
import { gtag } from "@/utils/ga";

interface SidebarFooterProps {
  name?: string;
  company?: string;
  open: boolean;
  onToggle: () => void;
}

export default function SidebarFooter({ name, company, open, onToggle }: SidebarFooterProps) {
  const auth = useAuth();
  const router = useRouter();

  const logout = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    auth.logout();
    router.push(routes.login(router.query));

    gtag("event", "click_sidebar_footer", {
      product: "main",
      view: "sidebar",
      action: "click",
      value: "log_out",
    });
  };

  const getAvatarInitials = (name?: string) => {
    if (!name) return "";

    const parts = name.split(" ");

    if (parts.length > 1) {
      // Return initials of first two names
      return `${parts[0][0] || ""}${parts[1][0] || ""}`.toUpperCase();
    } else {
      // Return first two letters of the single name
      return parts[0].substring(0, 2);
    }
  };

  return (
    <div className="pt-6 ml-6 mr-6 border-t border-blueGray100 dark:border-blueGray700">
      <div className="flex">
        <div className="flex items-center flex-1 min-w-0">
          <div>
            <Avatar alt={getAvatarInitials(name)} dark={false} activeStatus size={"s"} />
          </div>
          <div className="flex-1 min-w-0 ml-2.5">
            <P
              type="small"
              leading={4}
              className="pr-1 font-medium truncate w-36 dark:text-white text-primaryBlue900"
            >
              {name}
            </P>
            <P type="small" leading={4} className="font-medium uppercase truncate text-blueGray700">
              {company}
            </P>
          </div>
        </div>
        <div data-cy="sidebar-footer-icon" className="text-primaryBlue900 dark:text-white shrink-0">
          {!open && <ArrowUpIcon onClick={onToggle} className="cursor-pointer" size={16} />}
          {open && <ArrowDownIcon onClick={onToggle} className="cursor-pointer" size={16} />}
        </div>
      </div>
      {open && (
        <div className="my-6">
          <Button
            dataAttributes={{ cy: "logout-button" }}
            leftIcon="unlock"
            variant="TEXT"
            onClick={logout}
          >
            <FormattedMessage defaultMessage="Logout" description="Logout button" id="Xl2OHD" />
          </Button>
        </div>
      )}
    </div>
  );
}
