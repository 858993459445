import { useRouter } from "next/router";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { getCompany } from "@/client/api";
import {
  getUserData,
  isAuthenticated,
  passwordReset as passwordResetAPI,
  signIn,
  signout,
  updateUserData as updateUser,
} from "@/client/auth";
import { routes } from "@/client/routes";
import { isSupportedCountry } from "@/client/utils";
import NotFound from "@/components/Pages/NotFound/NotFound";
import { Company, Country, Language } from "@/components/Types";
import { UserMetadata } from "@/server/userManagement";

export const STORAGE_KEYS = {
  TOKEN: "access_token",
};

export interface User {
  email: string;
  country: Country;
  lang: Language;
  tin: string;
  name: string;
  company_id: string;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface AuthContextProps {
  isLoggedIn: boolean;
  processing: boolean;
  loginError: boolean;
  loginData: LoginData | null;
  user: User | null;
  company: Company | null;
  login: (email: string, password: string) => Promise<void>;
  passwordReset: (email: string) => Promise<void>;
  logout: () => void;
  updateUserData: (data: UserMetadata) => void;
  updateCompany: (data: Company) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  processing: false,
  loginError: false,
  loginData: null,
  user: null,
  company: null,
  login: () => new Promise((res) => res),
  passwordReset: () => new Promise((res) => res),
  logout: () => false,
  updateUserData: (data: UserMetadata) => data,
  updateCompany: (data: Company) => data,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const withoutAuthentication = (WrappedComponent) => {
  const RequiresNonAuthentication = (props) => {
    const auth = useAuth();
    const router = useRouter();

    if (auth.processing || !router.isReady) {
      return null;
    } else if (auth.isLoggedIn) {
      router.push(`/${router.query.country}`);
      return null;
    } else {
      if (isSupportedCountry(router.query.country as string)) {
        return <WrappedComponent {...props} />;
      } else {
        return <NotFound />;
      }
    }
  };

  return RequiresNonAuthentication;
};

export type WithAuthenticationProps = {
  children?: ReactNode;
};

export const withAuthentication = (WrappedComponent) => {
  return (props) => (
    <WithAuthentication>
      <WrappedComponent {...props} />
    </WithAuthentication>
  );
};

export const WithAuthentication = ({ children }: WithAuthenticationProps) => {
  const auth = useAuth();
  const router = useRouter();

  if (auth.processing || !router.isReady) {
    return null;
  } else if (
    (auth.isLoggedIn && router.query.country !== auth?.user?.country) ||
    !isSupportedCountry(router.query.country as string)
  ) {
    return <NotFound />;
  } else if (!auth.isLoggedIn) {
    router.push(`/${router.query.country}/login`);
    return null;
  } else {
    if (children) {
      return <>{children}</>;
    } else {
      if (router.query.country) {
        router.push(`/${router.query.country}${routes.dashboard}`);
      }
      return null;
    }
  }
};

function useProvideAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginData | null>(null);
  const [processing, setProcessing] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [company, setCompany] = useState<Company | null>(null);
  const router = useRouter();

  const login = async (email: string, password: string) => {
    setLoginError(false);
    setLoginData({ email, password });

    try {
      await signIn(email, password, router.query.country as string);

      const profile = getUserData();
      const companyRes = await getCompany(profile?.company_id);

      // TODO: Remove `unstable_batchedUpdates` when React batched setStates are released for promises by default
      // Ref: https://github.com/facebook/react/issues/16387#issuecomment-521623662
      ReactDOM.unstable_batchedUpdates(() => {
        setCompany(companyRes?.data);
        setIsLoggedIn(true);
        setUser(getUserData());
        setProcessing(false);
      });
    } catch (err) {
      setProcessing(false);
      setLoginError(true);
      throw err;
    }
  };

  const passwordReset = (email: string) => {
    return new Promise<void>((resolve, reject) => {
      passwordResetAPI(email)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  };

  const logout = () => {
    setIsLoggedIn(false);
    signout();
  };

  const updateUserData = (data: UserMetadata) => {
    updateUser(data);
    setUser(getUserData());
  };

  const updateCompany = (data) => {
    setCompany(data);
  };

  useEffect(() => {
    const updateData = async () => {
      const profile = getUserData();
      const companyRes = await getCompany(profile?.company_id);
      // TODO: Remove `unstable_batchedUpdates` when React batched setStates are released for promises by default
      // Ref: https://github.com/facebook/react/issues/16387#issuecomment-521623662
      ReactDOM.unstable_batchedUpdates(() => {
        setCompany(companyRes?.data);
        setIsLoggedIn(true);
        setUser(getUserData());
        setProcessing(false);
      });
    };

    if (isAuthenticated()) {
      updateData();
    } else {
      setProcessing(false);
      setIsLoggedIn(false);
    }
  }, [process.browser]);

  return {
    isLoggedIn,
    processing,
    loginError,
    loginData,
    user,
    company,
    login,
    passwordReset,
    logout,
    updateUserData,
    updateCompany,
  };
}
