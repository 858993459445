import { detect } from "detect-browser";
import compare from "just-compare";

import SuggestBrowser from "@/components/SuggestBrowser/SuggestBrowser";

const browser = detect();

type SupportedBrowsersProps = {
  children: any;
};

export const NOT_SUPPORTED_BROWERS = [
  {
    name: "chrome",
    blackList: ["95.0.4637"],
    limitVersion: 5,
  },
  {
    name: "edge-chromium",
    blackList: [],
    limitVersion: 5,
  },
  {
    name: "ie",
    blackList: [],
    limitVersion: 5,
  },
  {
    name: "firefox",
    blackList: [],
    limitVersion: 5,
  },
  {
    name: "safari",
    blackList: [],
    limitVersion: 5,
  },
  {
    name: "opera",
    blackList: [],
    limitVersion: 5,
  },
];

export const useIsNotBrowserSupported = () => {
  return NOT_SUPPORTED_BROWERS.some((b) => {
    if (b.name === browser?.name) {
      return (
        b.blackList.some((v) => compare(browser.version, v)) ||
        parseInt(browser.version?.split(".")[0] || "0") < b.limitVersion
      );
    }
    return false;
  });
};

export const SupportedBrowsers = ({ children }: SupportedBrowsersProps) => {
  const isNotBrowserSupported = useIsNotBrowserSupported();

  return isNotBrowserSupported ? <SuggestBrowser /> : children;
};
