import Image from "next/image";
import { FormattedMessage } from "react-intl";

import { routes } from "@/client/routes";
import SidebarLayout from "@/components/Layouts/SidebarLayout";
import A from "@/components/Typography/A";
import { H3 } from "@/components/Typography/H";
import P from "@/components/Typography/P";
import { Images } from "@/components/Utils/Images";
import { useAuth } from "@/hooks/useAuth";

function Dashboard404() {
  const auth = useAuth();

  const returnToDashboard = (
    <A
      className="text-primaryBlue500"
      href={routes.dashboard({
        country: auth?.user?.country,
      })}
    >
      <FormattedMessage
        defaultMessage="return to the Dashboard"
        description="Pages > Not found > Dashboard 404 > Return to the Dashboard"
        id="yeGuGP"
      />
    </A>
  );

  const dropUsALine = (
    <A className="text-primaryBlue500" href="https://www.fonoa.com/contact-us">
      <FormattedMessage
        defaultMessage="drop us a line"
        description="Pages > Not found > Dashboard 404 > Drop us a line"
        id="kamnDo"
      />
    </A>
  );

  return (
    <SidebarLayout windowTitle="Fonoa Dashboard">
      <div className="mt-40 text-center">
        <Image
          src={Images.notFoundPage.src}
          alt={Images.notFoundPage.alt}
          width={Images.notFoundPage.width}
          height={Images.notFoundPage.height}
        />
        <H3 color="blueGray600" className="mx-auto mt-10 text-lg font-medium max-w-84">
          <FormattedMessage
            defaultMessage="Sorry we couldn’t find that page. :("
            description="Pages > Not found > Dashboard 404 > Title"
            id="oJl2V3"
          />
        </H3>
        <P className="mx-auto mt-4 text-sm max-w-80 text-blueGray600">
          <FormattedMessage
            defaultMessage="Our API is reliable but mishaps do happen. You can {returnToDashboard}, or {dropUsALine} if you can’t find what you’re looking for."
            id="oGgQbm"
            description="Pages > Not found > Dashboard 404 > Helper text"
            values={{ returnToDashboard, dropUsALine }}
          />
        </P>
      </div>
    </SidebarLayout>
  );
}

export default Dashboard404;
