import classNames from "classnames";
import { MouseEvent, ReactNode } from "react";
export type IBadgeStates = "Default" | "Secondary" | "Alert" | "Warning";

const TEXT_LIGHT_VARIANT_MAPS: Record<IBadgeStates, string> = {
  Default: "bg-primaryBlue10 text-info",
  Secondary: "bg-teal25 text-teal600",
  Alert: "bg-dangerBg text-danger",
  Warning: "bg-warningBg text-warningActive",
};

const TEXT_DARK_VARIANT_MAPS: Record<IBadgeStates, string> = {
  Default: "dark:bg-info dark:text-white",
  Secondary: "dark:bg-teal600 dark:text-white",
  Alert: "dark:bg-danger dark:text-white",
  Warning: "dark:bg-warning dark:text-white",
};

interface BadgeProps {
  children?: number | ReactNode;
  className?: string;
  state?: IBadgeStates;
  size?: number;
  onClick?: (event: MouseEvent) => void;
}

export default function Badge({ children, className, state = "Default", onClick }: BadgeProps) {
  return (
    <div
      className={classNames(
        "inline-block align-middle text-center h-6 w-6 text-xs rounded-full",
        TEXT_LIGHT_VARIANT_MAPS[state],
        TEXT_DARK_VARIANT_MAPS[state],
        className
      )}
      onClick={onClick}
    >
      <span className="leading-loose">{children}</span>
    </div>
  );
}
