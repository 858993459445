import classNames from "classnames";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function OutsideIcon({
  size = 32,
  className = "",
  style
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 10 10`}
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02081 0.535645C8.5731 0.535644 9.02081 0.983359 9.02081 1.53564V7.60671L7.64263 7.60671L7.64263 2.88835L1.02279 9.50819L0.0482712 8.53366L6.66811 1.91383L1.94975 1.91383L1.94975 0.535645L8.02081 0.535645Z"
      />
    </svg>
  );
}
